import React from 'react';
import sumBy from 'lodash/sumBy';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, Legend } from 'recharts';

import { usePaperStyles, Doubletopinfo,  } from '../components/Covidcommon';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import apiConfig from '../config';

import Fetchhistdata from '../hoc/Fetchhistdata';

const useLoaderStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
		textAlign: 'center',
		marginTop: '140px',
		'& > * + *': {
			marginLeft: theme.spacing(2),
	  },
	},
}));

const RADIAN = Math.PI / 180;
const pieCustomLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, name, fill
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  // const radius2 = innerRadius + (outerRadius - innerRadius) * 0.5;
  // const x2 = cx + radius2 * Math.cos(-midAngle * RADIAN);
  // const y2 = cy + radius2 * Math.sin(-midAngle * RADIAN);

  return (
    <React.Fragment>
    <text fontSize={25} x={x} y={y} fill='#fff' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
        {/* {value !=0 ? `${value}` : null} */}
    </text>
    <text fontSize={11} x={x} y={y} dy={18} fill='#fff' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {value !=0 ? `${name}` : null}
    </text>
    </React.Fragment>
  );
};


const RenderVerticalBarGraph = ({chartdata, height}) => {
  // console.log(chartdata);
  return(
    <ResponsiveContainer width='100%' height={height}>
      <BarChart
        data={chartdata}
        layout="vertical"
        margin={{
          top: 0, right: 20, left: 20, bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        {/* {props.customtick ?
          <XAxis dataKey="y" height={50} tick={<CustomizedAxisTick />} />
          :
          <XAxis dataKey="y" />
          } */}

        <YAxis width={90} type="category" name="Age Group" dataKey="AgeGroup" label={{ value: 'Age Group', angle: -90, position: 'insideLeft' }} />
        <XAxis type="number" />
        <Tooltip />
        <Legend />
        {/* <Tooltip content={<CustomTooltip tooltiptitle={props.tooltiptitle} />} /> */}
        <Bar name="Male" stackId="a"  dataKey="Male" fill="#0277BD" />
        {/* <Bar name="Cases" dataKey="x2" fill="#558B2F" /> */}
        <Bar name="Female" stackId="a" dataKey="Female" fill="#EC407A" />
      </BarChart>
    </ResponsiveContainer>
  )
}

const RenderPieGraph = ({height, chartdata, color}) => {
  // console.log(chartdata);
    return(
        <ResponsiveContainer width='100%' height={height}>
            <PieChart>
              <Pie 
                dataKey="value"
                data={chartdata}
                fill="#8884d8"
                label={pieCustomLabel}
                labelLine={false}
                isAnimationActive={false}
                >
                {
                  chartdata.map((entry, index) => <Cell key={`cell-${index}`} fill={color[index % color.length]} />)
                }
              </Pie>
              {/* <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
              {/* <Tooltip /> */}
            </PieChart>
        </ResponsiveContainer>
    )
}

const Pieloader = () => {
  const classes = usePaperStyles();
  const loadclasses = useLoaderStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return(
        <Container maxWidth="lg">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper className={fixedHeightPaper} square elevation={0}>
                <div className={loadclasses.root}>
					        <CircularProgress size={100} disableShrink />
        				</div>
              </Paper>
            </Grid>
        </Container>
    )
}

const DemographicsGender = ({endpoint}) => {
    // const [isLoaded, setIsLoaded] = useState(false);
    // const [maleCases, setMaleCases] = useState(null);
    // const [femaleCases, setFemaleCases] = useState(null);
    // const [ageGenderCases, setAgeGenderCases] = useState([]);

    // const [apiendpoint, setAPIEndPoint] = useState(endpoint);
    // const [ageGenderMortality, setAgeGenderMortality] = useState([]);
    // const [ageGenderRecovery, setAgeGenderRecovery] = useState([]);
    // const [asOfDate, setAsOfDate] = useState(null);

    const { loading, results, error } = Fetchhistdata(endpoint);

    // useEffect(() => {
    //     // if (typeof endpoint === 'undefined') {
    //     //   setAPIEndPoint(apiConfig.apiUrl + 'demographics/gender/');
    //     // } else {
    //     //   setAPIEndPoint(endpoint);
    //     // }
    //     async function fetchData() {
    //         // if (endpoint === "") then 
            
    //         const res = await fetch(endpoint);
    //         const apidata = await res.json();
    //         // const asofdate =
    //         setAsOfDate(apidata.asofdate);
    //         // setMaleCases(sumBy)
    //         const json = apidata.data;
    //         // console.log(json);
    //         // console.log(sumBy(json,'Male'));
    //         setMaleCases(sumBy(json,'Male'));
    //         setFemaleCases(sumBy(json,'Female'));
    //         setAgeGenderCases(json);
    //         setIsLoaded(true);    
    //     }

    //     fetchData();
    // },[endpoint]);

    
    const classes = usePaperStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    if (!loading) {
      // console.log(endpoint);

      const maleCases = sumBy(results.data,'Male');
      const femaleCases = sumBy(results.data,'Female');
      const ageGenderCases = results.data;

      return(
          	<Container maxWidth="lg">
				<Typography variant="h5" component="h5" gutterBottom>
					<Box fontWeight="fontWeightLight">
					Demographics
					</Box>
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<Paper square className={fixedHeightPaper} elevation={2}>
						<Doubletopinfo
							title1="Male"
							value1={maleCases}
							// change1={change1}
							// pctchg1="pctchg"

							title2="Female"
							value2={femaleCases}
							// change2={change2}
						/>

						<Divider />
						<RenderPieGraph
							height={350}
							chartdata={[{name:'Male', value: maleCases},{name:'Female',value:femaleCases}]}
							color={['#0277BD', '#EC407A']} />
						</Paper>
					</Grid>

              		{/* Age Group break down per gender */}

					<Grid item xs={12} sm={12} md={8} lg={8}>
						<Paper square className={fixedHeightPaper} elevation={2} style={{height:500}}>
						{/* <Doubletopinfo
							title1="Male"
							value1={maleCases}
							// change1={change1}
							// pctchg1="pctchg"

							title2="Female"
							value2={femaleCases}
							// change2={change2}
						/>

						<Divider /> */}
						<RenderVerticalBarGraph
							height={500}
							chartdata={ageGenderCases}
						/>
						</Paper>
					</Grid>
			  </Grid>
          </Container>
      )
    } else {
        return(
          <Pieloader />
        )
    }

}

export default DemographicsGender;