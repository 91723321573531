import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import { Title, usePaperStyles } from './Covidcommon';

const useStyles = makeStyles({
	tablecell: {
		width: '20%'
	}
});

export default function Lastweektable(props) {
	const classes = useStyles();
	const paperclasses = usePaperStyles();
	// console.log(rows);
	let xs = 12;
	let sm = 12;
	let md = 6;
	let lg = 6;

	if (props.size === 'small') {
		xs = 12;
		sm = 12;
		md = 4;
		lg = 4;
	}

	const tabledata = props.casedata.slice(0).reverse();
	const [page, setPage] = useState(0);
	const rowsPerPage = 7;
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, tabledata.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };

	return (
		// <TableContainer component={Paper}>
		<Grid item xs={xs} sm={sm} md={md} lg={lg}>
			<Paper square className={paperclasses.paper} elevation={2}>
				<Title>Last 14 days data</Title>
				<Table className={classes.table} aria-label="simple table" >
					<TableHead>
					<TableRow>
						<TableCell className={classes.tablecell}>Date</TableCell>
						{props.size !== 'small'
						? <TableCell className={classes.tablecell} align="right">Active</TableCell>
						: null
						}
						<TableCell className={classes.tablecell} align="right">New</TableCell>
						<TableCell className={classes.tablecell} align="right">Recy</TableCell>
						<TableCell className={classes.tablecell} align="right">Death</TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
					{(rowsPerPage > 0
            			? tabledata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            			: tabledata
          			).map((row) => (
						<TableRow key={row.t}>
						<TableCell component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
							{row.t}
						</TableCell>
						{props.size !== 'small'
						? <TableCell align="right">{row.a}</TableCell>
						: null
						}
						<TableCell align="right">{row.n}</TableCell>
						<TableCell align="right">{row.r}</TableCell>
						<TableCell align="right">{row.d}</TableCell>
						</TableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
						<TableCell colSpan={2} />
						</TableRow>
					)}
					
					</TableBody>
					<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[7]}
							colSpan={(props.size !== 'small' ? 5 : 4)}
							count={tabledata.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							/>
					</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		</Grid>
		// </TableContainer>
	);
}