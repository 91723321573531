import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// import Fab from '@material-ui/core/Fab';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import HomeIcon from '@material-ui/icons/Home';
import PublicIcon from '@material-ui/icons/Public';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleIcon from '@material-ui/icons/People';
import LocationCityIcon from '@material-ui/icons/LocationCity';
// import StorageIcon from '@material-ui/icons/Storage';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ViewListIcon from '@material-ui/icons/ViewList';
import BusinessIcon from '@material-ui/icons/Business';

import { Link } from "react-router-dom";

// import { Alert, AlertTitle } from '@material-ui/lab';
// import Grid from '@material-ui/core/Grid';

// import AntiScamLogo from './image'

const actions = [
    { icon: <HomeIcon />, name: 'Home', link: '/' },
    { icon: <PeopleIcon />, name: 'New Cases', link: '/newcasesoverview' },
    { icon: <LocationCityIcon />, name: 'Metro Manila Stats', link: '/curves/metromanila' },
    { icon: <AssessmentIcon />, name: 'DOH Data Stats', link: '/dohdatastats' },
    { icon: <PublicIcon />, name: 'Choropleth', link: '/choropleth' },
    { icon: <FormatListNumberedIcon />, name: 'Leaderboards', link: '/leaderboards' },
    { icon: <ViewListIcon />, name: 'Tabular', link: '/tabular' },
    { icon: <BusinessIcon />, name: 'Key Cities', link: '/keycities' },
  ];

export default function Header() {

    const [open, setOpen] = React.useState(false);

    const fabstyle = {
        margin: 0,
        top: 'auto',
        right: 25,
        bottom: 25,
        left: 'auto',
        position: 'fixed',
        zIndex: 99,
    };

    // function handleClick() {
    //     window.scrollTo(0, 0);
    //     return null;
    // }

    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = (inaction) => {
        // console.log(inaction)
        if (inaction === 'click') {
            window.scrollTo(0, 0);
        }
        setOpen(false);
      };

    return(
        <Container maxWidth="lg">
            <div style={{display: 'flex', paddingTop: '5px'}}>
                <img alt="Agile Intelligence Logo" height={50}  style={{paddingRight: '10px'}} src={window.location.origin + '/images/Agile50x50.png'} />
                <Typography style={{paddingTop: '10px'}} variant="h6" component="h1" gutterBottom>
                    Agile Intelligence COVID-19 Dashboard
                </Typography>
            </div>
            {/* <Grid container spacing={5} justify="center">
                <Grid item xs={12} sm={8}>
                    <Alert severity="warning">
                        <AlertTitle>ANNOUNCEMENT</AlertTitle>
                        DOH is currently improving the reporting mechanisms of COVID-19 testing laboratories to resolve issues with their reporting system. In the mean time they have paused releasing futher updates until those issues are resolved.
                    </Alert>
                </Grid>
            </Grid> */}
            {/* <Fab id="fabhome" style={fabstyle} color='primary' onClick={handleClick} component={Link} to="/">
                <HomeIcon />
            </Fab> */}
            <SpeedDial
                ariaLabel="Menu"
                style={fabstyle}
                icon={<SpeedDialIcon />}
                onClose={() => handleClose('out')}
                onOpen={handleOpen}
                open={open}
            >
                {actions.reverse().map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    // tooltipOpen
                    component={Link}
                    to={action.link}
                    onClick={() => handleClose('click')}
                />
                ))}
            </SpeedDial>
        </Container>
    )
}