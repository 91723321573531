import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { Coviddatacardloading } from './Covidcommon';

//Cards
import Typography from '@material-ui/core/Typography';

import Lastweektable from './Lastweektable';

import Box from '@material-ui/core/Box';

import { Statspanel }  from './Dailydatacard';

import {Casespiechart, Casesareachart, Dailysimplecaseschart, Dailysimplecaseschartwithsma} from './Charts';

import { Showrecoverytimechart, Showdeadillnesschart } from '../containers/MiscChart';

// import {Philmap, Showregionname} from './Philmap';
// import {Showregionname} from './Philmap';

import {Philmap} from './Philmapv2';

import Provincelist from './Provincelist';

import Municipalitylist from './Municipalitylist';

// function RegiontemplateA(props) {
const RegiontemplateA = React.memo((props) => {
    // let mainheader;

    // (props.loading ? console.log('Loading...') : console.log(props.data.mainheader));
    
    // if (props.sectiontype === 'region') {
        // mainheader = (props.loading ? 'loading...' : Showregionname(props.data.mainheader));
    let mainheader = (props.loading ? 'loading...' : props.data.mainheader);
    // } else {
    //     mainheader = (props.loading ? 'loading...' : props.data.mainheader);
    // }
    
    return (
        // <div className={classes.root}>
        <div>
        {/* <Container maxWidth="xl"> */}
        <Container maxWidth="lg">


            {/* Daily data */}

            <Typography variant="h3" component="h2" gutterBottom>
                <Box fontWeight="fontWeightLight">
                    {/* {props.loading ? 'loading...' : Showregionname(props.data.mainheader)} */}
                    {mainheader}
                    </Box>
                {/* <Moment format="MMM D, YYYY">{latestDate}</Moment> (ECQ Day 17) */}
            </Typography>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="h5" component="h5" gutterBottom>
                        <Box fontWeight="fontWeightLight">
                        Geographic
                        </Box>
                    </Typography>
                    {props.loading
                    ? <Coviddatacardloading height={800} />
                    : <Philmap />
                    
                    }
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="h5" component="h5" gutterBottom>
                        <Box fontWeight="fontWeightLight">
                        Top Line Statistics ({props.loading ? 'loading...' : props.data.latestdate})
                        </Box>
                    </Typography>

                    {props.loading
                    ? <Coviddatacardloading height={750} />
                    : <Statspanel data={props.data} datatype={props.sectiontype} header={mainheader} />
                    }

                </Grid>


            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {props.sectiontype === 'province'
                        ?   <Grid container spacing={1}>
                                {props.loading
                                ? <Coviddatacardloading height={800} />
                                : <Municipalitylist headername={props.data.mainheader} province={props.data.province} />
                                }
                            </Grid>
                        : null
                    }

                    {props.sectiontype === 'region'
                    ?   <Grid container spacing={1}>
                            {/* {props.loading || props.data.mainheader === 'Philippines' ? null : <Provincelist regionid={props.data.mainheader} />} */}
                            {props.loading ? null : <Provincelist regionid={props.data.mainheader} />}
                        </Grid>
                    : null
                    }

                </Grid>

                {props.sectiontype === 'province'
                ? <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container spacing={1}>
                            {props.loading ? null : <Provincelist regionid={props.data.regionid} headername={props.data.mainheader} /> }
                    </Grid>
                </Grid>
                : null
                }
            </Grid>

            <br />

            <Typography variant="h5" component="h5" gutterBottom>
                <Box fontWeight="fontWeightLight">
                Charts ({props.loading ? 'loading...' : props.data.latestdate})
                </Box>
            </Typography>

            <Grid container spacing={1}>

                <Grid item xs={12} sm={12} md={8} lg={8}>
                    {props.loading
                    ? <Coviddatacardloading height={480} />
                    : <Casesareachart data={props.data.totalsdata} latestdate={props.data.latestdate} />
                    }
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>

                    {props.loading
                    ? <Coviddatacardloading height={480} />
                    : <Casespiechart
                        chartdata={props.data.casedivision}
                    />
                    }

                </Grid>

                {/* Show New Cases */}

                <Grid item xs={12} sm={12} md={6} lg={6}>
                
                    {props.loading
                    ? <Coviddatacardloading height={450} />
                    : <Dailysimplecaseschartwithsma
                        chartdata={props.data.combdailydata}
                        datakey = 'c'
                        smakey = 'csma'
                        title = 'Daily New Cases'
                        titlecolor = '#039BE5'
                    />
                    }

                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                
                    {props.loading
                    ? <Coviddatacardloading height={450} />
                    : <Dailysimplecaseschartwithsma
                        chartdata={props.data.combdailydata}
                        datakey = 'do'
                        smakey = 'dosma'
                        title = 'Daily New Cases based on Date of Onset'
                        titlecolor = '#039BE5'
                    />
                    }

                </Grid>

                {/* Show Recoveries */}

                <Grid item xs={12} sm={12} md={6} lg={6}>
                
                    {props.loading
                    ? <Coviddatacardloading height={450} />
                    : <Dailysimplecaseschartwithsma
                        chartdata={props.data.combdailydata}
                        datakey = 'r'
                        smakey ='rsma'
                        title = 'Daily Recoveries'
                        titlecolor = '#4DB6AC'
                    />
                    }

                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Showrecoverytimechart sectiontype={props.sectiontype} location={props.data.mainheader} />
                </Grid>

                {/* Show Deaths */}

                <Grid item xs={12} sm={12} md={6} lg={6}>
                
                    {props.loading
                    ? <Coviddatacardloading height={450} />
                    : <Dailysimplecaseschartwithsma
                        chartdata={props.data.combdailydata}
                        datakey = 'd'
                        smakey = 'dsma'
                        title = 'Daily Deaths'
                        titlecolor = '#d32f2f'
                    />
                    }

                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {/* {!props.loading && props.sectiontype === 'region'
                    ? <Showdeadillnesschart location={"/region/" + props.data.mainheader.replace(/[^A-Za-z]/g,'').toLowerCase()} />
                    : <Showdeadillnesschart location="/country/philippines" />
                    } */}
                    <Showdeadillnesschart sectiontype={props.sectiontype} location={props.data.mainheader} />
                </Grid>

                    
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                
                    {props.loading
                    ? <Coviddatacardloading height={450} />
                    : <Dailycaseschart
                        chartdata={props.data.combdailydata}
                    />
                    }

                </Grid> */}

                {props.loading
                    ? <Coviddatacardloading />
                    : <Lastweektable  casedata={props.data.tabledata} />
                }

            </Grid>
            <br />

        </Container>
        </div>
        );
    }
)

export default RegiontemplateA;