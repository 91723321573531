import React, { useState, useEffect } from 'react';

import apiConfig from '../config';

import RegionaltemplateA from '../components/RegionaltemplateA';

import Processhistdata from '../hoc/Processhistdata';

import Newcaseslocation from './Newcaseslocation';

import Leaderboardtemplate from './Leaderboardtemplate';

import Demographicsgender from '../containers/Demographicsgender';


// function Genhistdisplayv2(props) {
const Genhistdisplayv2 = React.memo((props) => {

    // let sectiontype = 'country';
    // let sectiontype;

    // console.log(props.location.pathname);

    // const [sectionType, setSectionType] = useState('test');
    // const [loading, setLoading] = useState(true);
    // const [locationData, setLocationData] = useState([]);
    // const [demographicEndpoint, setDemographicEndpoint] = useState(null);
    const [locState, setLocState] = useState({
        sectionType:null,
        locationData: [],
        demographicEndpoint: null,
        loading: true
    })

    useEffect(() => {

        async function fetchData() {
            try {
                let endpointstring = apiConfig.apiUrl + "historical/";
                let demographicendpoint = apiConfig.apiUrl + 'demographics/gender/';
                let tempsectiontype;

                if (props.location.pathname.includes("region")) {
                    endpointstring = apiConfig.apiUrl + "historical/region/" + props.match.params.endpointID;
                    tempsectiontype = 'region';
                    demographicendpoint = apiConfig.apiUrl + 'demographics/gender/region/' + props.match.params.endpointID;
                    // setDemographicEndpoint(apiConfig.apiUrl + 'demographics/gender/region/' + props.match.params.endpointID);
                } else if (props.location.pathname.includes("province")) {
                    endpointstring = apiConfig.apiUrl + "historical/province/" + props.match.params.endpointID;
                    tempsectiontype = 'province';
                    demographicendpoint = apiConfig.apiUrl + 'demographics/gender/province/' + props.match.params.endpointID;
                    // setDemographicEndpoint(apiConfig.apiUrl + 'demographics/gender/province/' + props.match.params.endpointID);
                } else {
                    demographicendpoint = apiConfig.apiUrl + 'demographics/gender/';
                    tempsectiontype = 'country';
                }

                // setReloading(true);
                const res = await fetch(endpointstring);
                const apidata = await res.json();
                // const histdata = Processhistdata(apidata);
                
                if (apidata) {
                    // Process data
                    const tempdata = Processhistdata(apidata);
                    setLocState({
                        sectionType: tempsectiontype,
                        locationData: tempdata,
                        demographicEndpoint: demographicendpoint,
                        loading: false
                    })
                    
                }

            } catch (error) {
                // setError(error.message);
                // setLoading(false);
                // setReloading(false);
                console.log(error.message);
                // setLoading(false);
            }
        }
        fetchData();
        
    },[props])

    if (locState.sectionType === 'country') {
        return(
            <React.Fragment>
                <RegionaltemplateA data={locState.locationData} loading={locState.loading} sectiontype={locState.sectionType} />
                <Newcaseslocation />
                <br />
                <Demographicsgender endpoint={locState.demographicEndpoint} />
                <br />
                <Leaderboardtemplate />
            </React.Fragment>
        )
    } else {
        return (        
            <React.Fragment>
                <RegionaltemplateA data={locState.locationData} loading={locState.loading} sectiontype={locState.sectionType} />
                <Demographicsgender endpoint={locState.demographicEndpoint} />
            </React.Fragment>
        )
    }
})

export default Genhistdisplayv2;