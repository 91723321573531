import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import CircularProgress from '@material-ui/core/CircularProgress';

import { numberWithCommas } from './Covidcommon';

import { usePaperStyles } from './Covidcommon';

import { useHistory } from "react-router-dom";

// const useStyles = makeStyles({
// 	tablecell: {
// 		width: '20%'
// 	}
// });

const useLoaderStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
		textAlign: 'center',
		marginTop: '140px',
		'& > * + *': {
			marginLeft: theme.spacing(2),
	  },
	},
  }));

function Leaderboardloading() {
	// const classes = useStyles();
    const loadclasses = useLoaderStyles();
    const paperclasses = usePaperStyles();

	// const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
	// console.log('go ' + props.title);

  	return (
		<Grid item xs={12} sm={6} md={4} lg={4}>
			<Paper className={paperclasses.paper} square elevation={2}>
				<div className={loadclasses.root}>
					<CircularProgress size={100} disableShrink />
					{/* <CircularProgress color="secondary" /> */}
				</div>
			</Paper>
		</Grid>
	);
}

const Twotablerowspecial = ({header, col1, col2, suffix}) => {

	let linkurl = null;
	const history = useHistory();

	let linkpref = null;
	switch(header) {
		case "Region":
			linkpref = '/region/';
			break;
		case "Province":
			linkpref = '/province/';
			break;
		default:
			linkpref = '/municipality/';
			break;
	}

	linkurl = linkpref + col1.replace(/[^A-Za-z]/g,'').toLowerCase();

	function handleClick(inlink) {
		history.push(inlink);
		window.scrollTo(0, 0);
        return null;
	}
	
	if (col1 === 'For Validation') {

		return (
			
			<TableRow key={col1}>
				<TableCell component="th" scope="row" style={{whiteSpace: 'nowrap', color: '#999'}}>
					{col1.substring(0,25)}
				</TableCell>
				<TableCell align="right" style={{color: '#999'}}>{col2}{suffix}</TableCell>
			</TableRow>
			
		)

	} else {

		return (
			
			<TableRow hover key={col1} onClick={() => handleClick(linkurl)} style={{ cursor: 'pointer' }}>
				<TableCell component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
					{col1.substring(0,25)}
				</TableCell>
				<TableCell align="right">{numberWithCommas(col2)}{suffix}</TableCell>
			</TableRow>
			
		)
	}
}

const Threetablerowspecial = ({header, col1, col2, col3, col2suffix, col3suffix}) => {

	let linkurl = null;
	const history = useHistory();

	let linkpref = null;
	switch(header) {
		case "Region":
			linkpref = '/region/';
			break;
		case "Province":
			linkpref = '/province/';
			break;
		default:
			linkpref = '/municipality/';
			break;
	}

	linkurl = linkpref + col1.replace(/[^A-Za-z]/g,'').toLowerCase();

	// if (header === "Province") {
	// 	linkurl = "/province/" + col1.replace(/[^A-Za-z]/g,'').toLowerCase();
	// } else {
	// 	linkurl = "/municipality/" + col1.replace(/[^A-Za-z]/g,'').toLowerCase();
	// }

	function handleClick(inlink) {
		history.push(inlink);
		window.scrollTo(0, 0);
        return null;
	}
	
	if (col1 === 'For Validation') {
		return (

			<TableRow hover key={col1}>
				<TableCell component="th" scope="row" style={{whiteSpace: 'nowrap', color:'#999'}}>
					{col1}
				</TableCell>
				<TableCell align="right" style={{color: '#999'}}>{col2}{col2suffix}</TableCell>
				<TableCell align="right" style={{color: '#999'}}>{col3}{col3suffix}</TableCell>
			</TableRow>
	
		)		
	} else {
		return (

			<TableRow hover key={col1} onClick={() => handleClick(linkurl)} style={{ cursor: 'pointer' }}>
				<TableCell component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
					{col1}
				</TableCell>
				<TableCell align="right">{col2}{col2suffix}</TableCell>
				<TableCell align="right">{numberWithCommas(col3)}{col3suffix}</TableCell>
			</TableRow>
	
		)
	}
}

function Twocollboard(props) {
	// const classes = useStyles();
	const paperclasses = usePaperStyles();
	// console.log(props.boarddata);

	const [page, setPage] = useState(0);
	const rowsPerPage = 5;
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.boarddata.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };
	
	return (
		// <TableContainer component={Paper}>
		<Grid item xs={12} sm={6} md={4} lg={4}>
			<Paper className={paperclasses.paper} elevation={2} square>
				{/* <Title>{props.title} {typeof props.asofdate !== 'undefined' ? "(" + props.asofdate + ")" : null}</Title> */}
				<Typography style={{fontSize:'18px'}}>
					{props.title} {typeof props.asofdate !== 'undefined' ? "(" + props.asofdate + ")" : null}
				</Typography>
				
				{typeof props.copy !== 'undefined'
				? <br />
				: null
				}
				<Typography variant="caption" component="p">{props.copy}</Typography>
                <Table aria-label="simple table" >
					
					<TableHead>
					<TableRow>
						{/* <TableCell className={classes.tablecell}>{props.header1}</TableCell>
						<TableCell className={classes.tablecell} align="right">{props.header2}</TableCell> */}
                        <TableCell >{props.header1}</TableCell>
						<TableCell align="right">{props.header2}</TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
					{(rowsPerPage > 0
            			? props.boarddata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            			: props.boarddata
          			).map((row) => (
						<Twotablerowspecial header={props.header1} col1={row.col1} col2={row.col2} suffix={props.suffix} key={row.col1} />
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
						<TableCell colSpan={2} />
						</TableRow>
					)}
					</TableBody>
					<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5]}
							colSpan={2}
							count={props.boarddata.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							/>
					</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		</Grid>
		// </TableContainer>
	);
}

// function Twocollboard(props) {
// 	// const classes = useStyles();
// 	const paperclasses = usePaperStyles();
// 	// console.log(props.boarddata);
	
// 	return (
// 		// <TableContainer component={Paper}>
// 		<Grid item xs={12} sm={6} md={4} lg={4}>
// 			<Paper className={paperclasses.paper} elevation={2}>
// 				<Title>{props.title} {typeof props.asofdate !== 'undefined' ? "(" + props.asofdate + ")" : null}</Title>
// 				{/* <Table className={classes.table} aria-label="simple table" > */}
//                 <Table aria-label="simple table">
// 					<TableHead>
// 					<TableRow>
// 						{/* <TableCell className={classes.tablecell}>{props.header1}</TableCell>
// 						<TableCell className={classes.tablecell} align="right">{props.header2}</TableCell> */}
//                         <TableCell >{props.header1}</TableCell>
// 						<TableCell align="right">{props.header2}</TableCell>
// 					</TableRow>
// 					</TableHead>
// 					<TableBody>
// 					{props.boarddata.map((row) => (
// 						<Twotablerowspecial header={props.header1} col1={row.col1} col2={row.col2} suffix={props.suffix} />
// 					))}
// 					</TableBody>
// 				</Table>
// 			</Paper>
// 		</Grid>
// 		// </TableContainer>
// 	);
// }

function Threecollboard(props) {
	// const classes = useStyles();
	const paperclasses = usePaperStyles();
	// console.log(rows);

	const [page, setPage] = useState(0);
	const rowsPerPage = 5;
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.boarddata.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };

	return (
		// <TableContainer component={Paper}>
		<Grid item xs={12} sm={12} md={6} lg={6}>
			<Paper className={paperclasses.paper} elevation={2} square>
				<Typography style={{fontSize:'18px'}}>
					{props.title} {typeof props.asofdate !== 'undefined' ? "(" + props.asofdate + ")" : null}
				</Typography>
				<br />
				<Typography variant="caption" component="p">
					{props.copy}
				</Typography>
                <Table aria-label="simple table" >
					<TableHead>
					<TableRow>
						{/* <TableCell className={classes.tablecell}>{props.header1}</TableCell>
						<TableCell className={classes.tablecell} align="right">{props.header2}</TableCell> */}
                        <TableCell >{props.header1}</TableCell>
						<TableCell align="right">{props.header2}</TableCell>
                        <TableCell align="right">{props.header3}</TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
					{(rowsPerPage > 0
            			? props.boarddata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            			: props.boarddata
          			).map((row) => (
						<Threetablerowspecial
							key={row.col1+row.col2}
							header={props.header1}
							col1={row.col1}
							col2={row.col2}
							col3={row.col3}
							col2suffix={props.col2suffix}
							col3suffix={props.col3suffix} />
						// <TableRow key={row.col1}>
						// <TableCell component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
						// 	{row.col1}
						// </TableCell>
						// <TableCell align="right">{row.col2}{props.col2suffix}</TableCell>
                        // <TableCell align="right">{row.col3}{props.col3suffix}</TableCell>
						// </TableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
						<TableCell colSpan={3} />
						</TableRow>
					)}
					</TableBody>
					<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5]}
							colSpan={3}
							count={props.boarddata.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							/>
					</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		</Grid>
		// </TableContainer>
	);
}

export {
    Leaderboardloading,
    Twocollboard,
	Threecollboard
}