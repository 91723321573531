const apiConfig = {
    appDomain: "https://covid19.agileintelligence.ph",
    apiUrl: "https://covid19.agileintelligence.ph/api/",

    // appDomain: "http://192.168.1.6",
    // apiUrl: "http://192.168.1.6/api/",

    // appDomain: "http://192.168.1.78",
    // apiUrl: "http://192.168.1.78/api/",

}

export default apiConfig;