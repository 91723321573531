import { useState, useEffect } from 'react';

// import apiConfig from '../config';
// import Processhistdata from './Processhistdata2';

function Fetchhistdata(histendpoint) {

	// console.log(props.location.pathname);

    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [error, setError] = useState("");
    // const [reloading, setReloading] = useState(true);
	
	useEffect(() => {
		async function fetchData() {            
            try {
                // setReloading(true);
                const res = await fetch(histendpoint);
                const apidata = await res.json();
                // const histdata = Processhistdata(apidata);
                
                if (apidata) {
                    // setResults(histdata);
                    setResults(apidata);
                    setLoading(false);
                    // setReloading(false);
                  }

            } catch (error) {
                setError(error.message);
                setLoading(false);
                // setReloading(false);
            }

            setLoading(false);
        }
		fetchData();
    },[histendpoint]);

    // console.log('From HOC: ' + loading);
    return {
        error, loading, results
        // error, loading, results, reloading
    };

}

export default Fetchhistdata;