import React from 'react';
import Typography from '@material-ui/core/Typography';

import {
    ZoomableGroup,
    ComposableMap,
    Geographies,
    Geography
  } from "react-simple-maps";

import { Link } from "react-router-dom";

// function checkDatachange(prevProps, nextProps) {
// 	return prevProps.mapdata === nextProps.mapdata && prevProps.ncdata === nextProps.ncdata && prevProps.dataType === nextProps.dataType && prevProps.geodata === nextProps.geodata;
// }

function checkDatachange(prevProps, nextProps) {
	// return prevProps.datatype === nextProps.datatype;
	return prevProps.datatype === nextProps.datatype && prevProps.geodata === nextProps.geodata;
}

const MapChart = React.memo(({ setTooltipContent, mapdata, ncdata, hcdata, datatype, geodata,mapheight,mapscale,maptype }) => {

	//Process data add Mortality and Recovery rate
	const finaldata = mapdata.map((tempdata) => {
		let mortality = tempdata.d/tempdata.c * 100;
		let recoveryrate = tempdata.r/tempdata.c * 100;
		let activecases = (tempdata.c - (tempdata.d + tempdata.r));
		return {psgc: tempdata.psgc, c: tempdata.c, d: tempdata.d, r: tempdata.r, ag: tempdata.ag, mr: mortality.toFixed(2), rr: recoveryrate.toFixed(2), a: activecases, lid: tempdata.lid}
	})

	const splitvallow = [10, 50, 100, 500, 1000];
	// const splitval = [10, 100, 500, 1000, 2000];
	const splitval = [100, 500, 1000, 2000, 3000];
	const splitvalnew = [10, 25, 50, 100, 200];
	const splitvalper = [1, 2, 3, 5, 10];
	const splitvalperhigh = [10, 20, 30, 50, 80];
	const splitvalpermor = [1, 5, 10, 15, 20];
	// const splitvalhc = [25,50,75];
	const splitvalhc = [25,35,55,65,75];
	// const splitvalhc = [10,25,50,65,75];

	// const colorscale = ["rgb(250,200,100)","rgb(255,190,100)","rgb(255,130,80)","rgb(255,80,0)","rgb(255,0,0)"];
	const colorscale = ["#ffeda9","#edad7b","#dc7251","#cc3929","#bb0000"];
	const colorscale_green = ["#ffeda9","#c0d580","#7bbc54","#39a32a","#018e06"];
	const colorscale_blue = ["rgb(0,220,255)","rgb(0,190,255)","rgb(0,130,255)","rgb(0,80,255)","rgb(0,0,255)"];
	// const colorscale_hc = ["#ffeda9","#dc7251","#bb0000"];
	// const colorscale_hc = ["#ffeda9","#dc7251","#018e06"];
	const colorscale_hc = ["#ffeda9","#edad7b","#dc7251","#cc3929","#bb0000"];

	
	function showlegend() {
		
		let tempcolscale, suffix = "", tempsplit;
		switch (datatype) {
			case "recoveries":
				tempsplit = splitvallow;
				tempcolscale = colorscale_green;
				break;
			case "death":
				tempsplit = splitvallow;
				tempcolscale = colorscale;
				break;
			case "growthrate":
				// maxval = maxgrowthrate;
				tempsplit = splitvalper;
				tempcolscale = colorscale;
				suffix = "%";
				break;
			case "mortalityrate":
				tempsplit = splitvalpermor;
				tempcolscale = colorscale;
				suffix = "%";
				break;
			case "recoveryrate":
				tempsplit = splitvalperhigh;
				tempcolscale = colorscale_green;
				suffix = "%";
				break;
			case "newcases":
				// maxval = maxnewcases;
				tempsplit = splitvalnew;
				tempcolscale = colorscale;
				suffix = "";
				break;
			case "active":
				tempsplit = splitval;
				tempcolscale = colorscale;
				// zerospecial = "#0E0";
				break;
			case "healthcare":
				tempsplit = splitvalhc;
				tempcolscale = colorscale_hc;
				// zerospecial = "#0E0";
				break;
			default:
				tempsplit = splitval;
				tempcolscale = colorscale;
		}

		return(
			<div style={{position: 'absolute', right: 0}}>
				<div style={{width: 60}}>
					<Typography variant="caption">Legends</Typography>
				</div>
				
				{
					tempsplit.map((perval, key) => {
						return(
							<div style={{width: 50, padding: 3, textAlign: 'left', backgroundColor: tempcolscale[key]}} key={key}>
							 	{/* {key === 0 ? " > " + Math.round(finaldata[0].c * perval) : Math.round(finaldata[0].c * perval)} */}
								 <Typography variant="caption"> 
								 {" < " + perval + suffix}
								 </Typography>
							</div>
						)
					})
                }
			</div>
		)

	}

    function getmundata(inpsgcdata) {
	  
		let locdata;

		if (datatype === 'newcases') {
			
			// locdata = ncdata.find(data => parseInt(data.psgc.substring(2, 8)) === parseInt(inpsgcdata));
			locdata = ncdata.find(data => parseInt(data.psgc) === parseInt(inpsgcdata));

			if (locdata) {

				// let splitper = [0.50,0.30,0.15,0.05];
				let bracket = splitvalnew.length - 1;

				for (let index = 0; index < splitvalnew.length; index++) {
					// if (locdata.nc >= (maxnewcases * splitper[index])) {
					// 	bracket = splitper.length - index;
					// 	index = splitper.length;
					// }
					
					if (locdata.nc < splitvalnew[index]) {
						bracket = index;
						index = splitvalnew.length;
					}
				}
				// fillcolor = colorscale_blue[bracket];
				return {locdatastr: [datatype,locdata.nc], fillcolor: colorscale[bracket], linkurl: locdata.lid } ;
			} else {
				return {locdatastr: [], fillcolor: "#ccc", linkurl: null };
			}
		
		} else if (datatype === 'healthcare') {

			locdata = hcdata.find(data => parseInt(data.psgc) === parseInt(inpsgcdata));

			if (locdata) {

				// let splitper = [0.50,0.30,0.15,0.05];
				let bracket = splitvalhc.length - 1;

				for (let index = 0; index < splitvalhc.length; index++) {
					// if (locdata.nc >= (maxnewcases * splitper[index])) {
					// 	bracket = splitper.length - index;
					// 	index = splitper.length;
					// }
					
					if (locdata.bo < splitvalhc[index]) {
						bracket = index;
						index = splitvalhc.length;
					}
				}

				// [10,25,50,65,75]
				// [75,65,50,25,10]
				// [00,01,02,03,04]
				// 14 - 4
				// 40 - 3
				// 56 - 2

				// 16 - 0
				// 2 - -1 

				// let bracket = splitvalhc.reverse().findIndex((split) => {
				// 	return parseFloat(locdata.bo) > split;
				// })

				// let tempbracket = [75,65,50,25,10];
				// let tempbracket = [...splitvalhc].reverse();

				// let bracket = tempbracket.findIndex((split) => {
				// 	return parseFloat(locdata.bo) > split;
				// })

				// if (bracket < 0) { bracket = 4 };

				// console.log(`${tempbracket} - ${locdata.bo} - ${bracket}`);

				// fillcolor = colorscale_blue[bracket];
				return {locdatastr: [datatype,locdata.nof,locdata.tb,locdata.ob,locdata.vb,locdata.bo], fillcolor: colorscale_hc[bracket], linkurl: locdata.lid} ;
			} else {
				return {locdatastr: [], fillcolor: "#ccc", linkurl: null };
			}

		} else {

			//   locdata = finaldata.find(data => parseInt(data.psgc.substring(2, 8)) === parseInt(inpsgcdata));
			locdata = finaldata.find(data => parseInt(data.psgc) === parseInt(inpsgcdata));

			if (locdata) {
				let bracket = splitval.length - 1;
				let fillcolor;

				switch(datatype) {

					case "death":
						if (locdata.d === 0) {
							// return {locdatastr: [datatype,locdata.c,locdata.r,locdata.d,locdata.ag], fillcolor: "#ccc" };
							fillcolor = "#ccc";
						} else {
							// maxval = Math.max.apply(Math, mapdata.map(function(o) { return o.d; }));
			
							for (let index = 0; index < splitval.length; index++) {
								if (locdata.d < splitval[index]) {
									bracket = index;
									index = splitval.length;
								}
							} 
							fillcolor = colorscale[bracket];
						}
						break;

					case "recoveries":

						if (locdata.r === 0) {
							fillcolor = "#ccc";
						} else {
			
							for (let index = 0; index < splitval.length; index++) {
								if (locdata.r < splitval[index]) {
									bracket = index;
									index = splitval.length;
								}
							} 
							fillcolor = colorscale_green[bracket];
						}
						break;

					case "growthrate":
						if (locdata.ag === 0) {
							fillcolor = "#ccc";
						} else {
			
							for (let index = 0; index < splitvalper.length; index++) {
								if (locdata.ag < splitvalper[index]) {
									bracket = index;
									index = splitvalper.length;
								}
							} 
							fillcolor = colorscale[bracket];
						}
						break;

					case "mortalityrate":
						if (locdata.mr === 0) {
							// return {locdatastr: [datatype,locdata.c,locdata.r,locdata.d,locdata.ag], fillcolor: "#ccc" };
							fillcolor = "#ccc";
						} else {
							
							for (let index = 0; index < splitvalpermor.length; index++) {
								if (locdata.mr < splitvalpermor[index]) {
									bracket = index;
									index = splitvalpermor.length;
								}
							} 
							fillcolor = colorscale[bracket];
							// return {locdatastr: [datatype,locdata.c,locdata.r,locdata.d,locdata.ag], fillcolor: colorscale[bracket] };
						}

						break;

					case "recoveryrate":
						if (locdata.rr === 0) {
							fillcolor = "#ccc";
						} else {
							
							for (let index = 0; index < splitvalperhigh.length; index++) {
								if (locdata.rr < splitvalperhigh[index]) {
									bracket = index;
									index = splitvalperhigh.length;
								}

							} 
							fillcolor = colorscale_green[bracket];
						}

						break;
					
					case "active":

						if (locdata.a === 0) {
							// fillcolor = "#0E0";
							fillcolor = "#ccc";
						} else {
			
							for (let index = 0; index < splitval.length; index++) {
							
								if (locdata.a < splitval[index]) {
									bracket = index;
									index = splitval.length;
								}
							} 
							fillcolor = colorscale[bracket];
						}
						break;

					default:
						for (let index = 0; index < splitval.length; index++) {
							
							if (locdata.c < splitval[index]) {
								bracket = index;
								index = splitval.length;
							}
						} 
						fillcolor = colorscale[bracket];
				}

				return {locdatastr: [datatype,locdata.c,locdata.r,locdata.d,locdata.ag,locdata.mr,locdata.rr,locdata.a], fillcolor: fillcolor, linkurl: locdata.lid } ;
			
				} else {
					// return {locdatastr: [], fillcolor: "#D6D6DA" } ;
					return {locdatastr: [], fillcolor: "#ccc", linkurl: null } ;
				}
			}

    }

    // function handleGeographyClick(geography) {
    //     // geography looks something like this:
    //     // { type: "Feature",  properties: {...}, geometry: {...} }
    //     const path = geoPath().projection(this.projection())
    //     const centroid = this.projection().invert(path.centroid(geography))
    //     this.setState({
    //       center: centroid,
    //       zoom: 4,
    //       currentCountry: geography.properties.iso_a3,
    //     })
    
    // }

    // function projection() {
    //     return geoTimes()
    //       .translate([this.props.width/2, this.props.height/2])
    //       .scale(160)
    // }

    return (
      <div style={{width: '100%', height: '100%', backgroundColor: '#fff', position: "relative" }}>
		{showlegend()}
        <ComposableMap data-tip="" projection='geoMercator' height={mapheight} projectionConfig={{ scale: mapscale, center: [122,13] }}>
        {/* <ComposableMap data-tip="" projection='geoMercator' height={800} projectionConfig={{ scale: 200 }}> */}
        {/* <ComposableMap data-tip="" projection='geoMercator' height={1200} projectionConfig={{ scale: 200 }}> */}
          {/* <ZoomableGroup maxZoom={15} zoom={3}> */}
          {/* <ZoomableGroup maxZoom={0} zoom={2} center={[122,18]}> */}
            {/* <Geographies geography={geoUrl}> */}
			<Geographies geography={geodata}>
              {({ geographies }) =>
                geographies.map(geo => {

					let geoname, geopsgc, mundata;
					// console.log(maptype);

					//Phil Map V2
					// if (maptype === 'Province') {
					// 	// console.log(geo.properties);
					// 	geoname = geo.properties.province;
					// 	geopsgc = (typeof geo.properties.psgc_province !== 'undefined' ? geo.properties.psgc_province.substring(2,6) : null);
						
					// } else {
					// 	geoname = geo.properties.NAME_1;
					// 	geopsgc = geo.properties.CC_1;
					// }

					//Phil Map V1
					geoname = geo.properties.NAME_1;
					geopsgc = geo.properties.CC_1; 

					mundata = getmundata(geopsgc);

					// const mundata = getmundata(typeof geo.properties.CC_2 === 'undefined' ? geo.properties.CC_1 : geo.properties.CC_2);
					
					if (mundata.linkurl !== null) {
						return(
							<Link to={(maptype === 'Region' ? '/region/' : '/province/') + mundata.linkurl} key={mundata.linkurl}>
								<Geography
									key={geo.rsmKey}
									geography={geo}
									onMouseEnter={() => {
									const { NAME_2 } = geo.properties;
									//   setTooltipContent(`${NAME_1} - ${NAME_2} - ${CC_2} - ${mundata.locdatastr}` );
									setTooltipContent([geoname,NAME_2,mundata.locdatastr]);
									}}
									onMouseLeave={() => {
									setTooltipContent([]);
									}}
									// onClick={() => {
									//       const { NAME_1 } = geo.properties;
									//     alert(`${NAME_1}`)}
									// }
									// onClick={}
									style={{
									default: {
										fill: mundata.fillcolor,
										outline: "none",
										stroke: "#555",
										strokeWidth: 0.1
									},
									hover: {
										fill: "#3FA9F5",
										outline: "none",
										stroke: "black",
										strokeWidth: 0.5,
										cursor: 'pointer'
									},
									pressed: {
										fill: "#E42",
										outline: "none"
									}
									}}
								/>
							</Link>
						)
					} else {
						return(
							<Geography
									key={geo.rsmKey}
									geography={geo}
									onMouseEnter={() => {
									const { NAME_2 } = geo.properties;
									//   setTooltipContent(`${NAME_1} - ${NAME_2} - ${CC_2} - ${mundata.locdatastr}` );
									setTooltipContent([geoname,NAME_2,mundata.locdatastr]);
									}}
									onMouseLeave={() => {
									setTooltipContent([]);
									}}
									// onClick={() => {
									//       const { NAME_1 } = geo.properties;
									//     alert(`${NAME_1}`)}
									// }
									// onClick={}
									style={{
									default: {
										fill: mundata.fillcolor,
										outline: "none",
										stroke: "#555",
										strokeWidth: 0.1
									},
									hover: {
										fill: "#3FA9F5",
										outline: "none",
										stroke: "black",
										strokeWidth: 0.5,
										cursor: 'pointer'
									},
									pressed: {
										fill: "#E42",
										outline: "none"
									}
									}}
								/>
						)
					}
                  })
              }
            </Geographies>
          {/* </ZoomableGroup> */}
        </ComposableMap>
        </div>
    );
},checkDatachange);

function Showmaphoverdata(props) {
    const { locdata } = props;
  
    if (locdata.length) {
		if (locdata[0] === 'newcases') {
			return(
			<p>
				New Cases: {locdata[1]} <br />
			</p>
			)
		
		} else if (locdata[0] === 'healthcare') {
			return(
				<p>
					{/* [datatype,locdata.nof,locdata.tb,locdata.ob,locdata.bv,locdata.bo] */}
					No. of Facilities: {locdata[1]} <br /> <br />
					Occupied Beds: {locdata[3]} <br />
					Vacant Beds: {locdata[4]} <br />
					Total Beds: {locdata[2]} <br /> <br />

					Bed Occupancy: {locdata[5]}%<br />
				</p>
			)
		} else {
			return(
			<p>
				Total Cases: {locdata[1]} <br />
				Avg Growth Rate: {locdata[4]}%<br /><br />
	
				Recoveries : {locdata[2]} <br />
				Recovery Rate: {locdata[6]}%<br /><br />
				
				Deaths: {locdata[3]} <br />
				Mortality Rate: {locdata[5]}%<br /><br />
	
				Active Cases: {locdata[7]}
			</p>
			)
		}
    } else {
      return(
        <p>No recorded cases</p>
      )
    }
  }

export {MapChart, Showmaphoverdata};