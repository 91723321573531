import React from 'react';
import Leaderboard from '../containers/Leaderboard';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

// function Leaderboardtemplate () {
const Leaderboardtemplate = React.memo(() => {
    return(
        <Container maxWidth="lg">
            
        <Typography variant="h5" component="h2" gutterBottom>
            <Box fontWeight="fontWeightLight">
            Leader Boards
            </Box>
        </Typography>

        <Grid container spacing={2}>

            <Leaderboard 
                boarddataendpoint="leaderboard/socialdistancingchampionmunicipality"
                title="Social Distancing Champions (Municipality)"
                header1="Municipality"
                header2="Last case date"
                header3="Days since last case"
                type="3col"
                col1="mu"
                col2="lc"
                col3="dp"
                copy="This identifies which municipalities or cities have had the longest time with no new cases since the first case."
            />

            <Leaderboard 
                boarddataendpoint="leaderboard/socialdistancingchampionprovince"
                title="Social Distancing Champions (Province)"
                header1="Province"
                header2="Last case date"
                header3="Days since last case"
                type="3col"
                col1="pr"
                col2="lc"
                col3="dp"
                copy="This identifies which provinces have had the longest time with no new cases since the first case."
            />

            <Leaderboard 
                boarddataendpoint="leaderboard/provincehighestavggrowthrate"
                title="Highest 7 day avg. growth rate (total cases)"
                header1="Province"
                header2="Growth Rate"
                header3="Total Cases"
                type="3col"
                col1="pr"
                col2="gr"
                col3="c"
                col2suffix="%"
                con3suffix=" days"
                copy="This identifies which provinces are having high rates of case increases."
            />

            <Leaderboard 
                boarddataendpoint="leaderboard/municipalityhighestavggrowthrate"
                title="Highest 7 day avg. growth rate (total cases)"
                header1="Municipality"
                header2="Growth Rate"
                header3="Total Cases"
                type="3col"
                col1="mu"
                col2="gr"
                col3="c"
                col2suffix="%"
                con3suffix=" days"
                copy="This identifies which municipalities or cities are having high rates of case increases."
            />

            <Leaderboard 
                boarddataendpoint="leaderboard/provincemostnewcaselast7days"
                title="Total New Cases (last 7 days)"
                header1="Province"
                header2="Cases"
                col1="pr"
                col2="c"
            />

            <Leaderboard 
                boarddataendpoint="leaderboard/municipalitymostnewcaselast7days"
                title="Total New Cases (last 7 days)"
                header1="Municipality"
                header2="Cases"
                col1="mu"
                col2="c"
            />

            <Leaderboard 
                boarddataendpoint="leaderboard/mostrecoveriesmunicipality"
                title="Highest no. of recoveries"
                header1="Municipality"
                header2="Total Recoveries"
                col1="mu"
                col2="r"
            />

            <Leaderboard 
                boarddataendpoint="leaderboard/deadliestmunicipality"
                title="Highest no. of death"
                header1="Municipality"
                header2="Total Death"
                col1="mu"
                col2="d"
            />

            {/* {props.isloaded ?
                <Covidtotals date={props.data.latestdate} totaldata={props.data.totaldata} />
            :
                <Coviddatacardloading />
            } */}

        </Grid>

        <br />

        </Container>

)})

export default Leaderboardtemplate;