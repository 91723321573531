import React, { useState , useEffect } from 'react';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { StatCard, numberWithCommas } from './Covidcommon';

// import { usePaperStyles, Doubletopinfo, Tripletopinfo } from './Covidcommon';


// theme = responsiveFontSizes(theme);

import appConfig from '../config';

// import Fetchhistdata from '../hoc/Fetchhistdata';
// import { useEffect } from 'react';

// function numberWithCommas(x) {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }


const Showmedpanel = ({loctype,locid}) => {

    const [medData, setMedData] = useState([]);

    useEffect(() => {
		async function fetchData() {            
            try {
                // setReloading(true);
                const res = await fetch(`${appConfig.apiUrl}healthcaretopline/${loctype}/${locid.replace(/[^A-Za-z]/g,'').toLowerCase()}`);
                const apidata = await res.json();
                // console.log('Go!');
                // console.log(apidata);
                // const histdata = Processhistdata(apidata);
                
                if (apidata) {
                    // setResults(histdata);
                    setMedData(apidata);
                    // setLoading(false);
                    // setReloading(false);
                  }

            } catch (error) {
                console.log(error);
                // setError(error.message);
                // setLoading(false);
                // setReloading(false);
            }

            // setLoading(false);
        }
		fetchData();
    },[locid]);

    // const facilitydata = Fetchhistdata(`${appConfig.apiUrl}healthcaretopline/${loctype}/${locid.replace(/[^A-Za-z]/g,'').toLowerCase()}`);

    if (medData.length > 0) {
        // console.log(medData.length);
        let totalbeds = (medData[0].o + medData[0].v);
        let peroccupancy = (medData[0].o / totalbeds) * 100;

        // console.log(peroccupancy);

        return(
            <Grid container item xs={12} spacing={0}>
                    <Tricard
                        title1="Bed Occupancy"
                        value1={peroccupancy.toFixed(2) + '%'}
                        copy1={`${locid} currenly has a total of ${numberWithCommas(totalbeds)} beds of which ${numberWithCommas(medData[0].o)} are occupied and ${numberWithCommas(medData[0].v)} vacant.`}
    
                        title2="Healthcare Facilities"
                        value2={numberWithCommas(medData[0].tf)}
                        copy2={`${numberWithCommas(medData[0].cf)} are already at more than 75% occupancy`}
    
                        title3="Ventilator Availability"
                        value3={numberWithCommas(medData[0].mv)}
                        copy3={`${numberWithCommas(medData[0].mo)} are currently in use`}
    
                        bgcolor='#0277bd'
                        />
                </Grid>
        )
    } else {
        return <p>loading...</p>;
    }

    

}

function Tricard(props) {
    const {title1, value1, subtext1, copy1, title2, value2, subtext2, copy2, title3, value3, subtext3, copy3, bgcolor} = props
    return (
    <React.Fragment>
        <Grid item xs={12} sm={4}>
          <StatCard
                title={title1}
                value={value1}
                copy={copy1}
                subtext={subtext1}
                bgcolor={bgcolor} />
        </Grid>
        <Grid item xs={12} sm={4}>
            <StatCard
                title={title2}
                value={value2}
                copy={copy2}
                subtext={subtext2}
                bgcolor={bgcolor} />
        </Grid>
        <Grid item xs={12} sm={4}>
            <StatCard
                title={title3}
                value={value3}
                copy={copy3}
                subtext={subtext3}
                bgcolor={bgcolor} />
        </Grid>
    </React.Fragment>
    );
}

const Statspanel = ({data,loading,datatype,header}) => {

    // function Tricard(props) {
    //     const {title1, value1, subtext1, copy1, title2, value2, subtext2, copy2, title3, value3, subtext3, copy3, bgcolor} = props
    //     return (
    //     <React.Fragment>
    //         <Grid item xs={12} sm={4}>
    //           <StatCard
    //                 title={title1}
    //                 value={value1}
    //                 copy={copy1}
    //                 subtext={subtext1}
    //                 bgcolor={bgcolor} />
    //         </Grid>
    //         <Grid item xs={12} sm={4}>
    //             <StatCard
    //                 title={title2}
    //                 value={value2}
    //                 copy={copy2}
    //                 subtext={subtext2}
    //                 bgcolor={bgcolor} />
    //         </Grid>
    //         <Grid item xs={12} sm={4}>
    //             <StatCard
    //                 title={title3}
    //                 value={value3}
    //                 copy={copy3}
    //                 subtext={subtext3}
    //                 bgcolor={bgcolor} />
    //         </Grid>
    //     </React.Fragment>
    //     );
    // }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function generatechangecopy(intype, curval, inchange) {

        const percentchange = Math.abs(((inchange / (curval === 0 ? 1 : curval)) * 100).toFixed(0));

        if (inchange !== 0) {
            if (inchange < 0 ) {
            //   textcolor = badcolor;
            //   arrowdirection = 'down';
            return "A " + percentchange + "% decrease or " + Math.abs(inchange) + " less " + intype + " than yesterday.";
            } else {
            //   textcolor = goodcolor;
            //   arrowdirection = 'up';
            return "A " + percentchange + "% increase or " + Math.abs(inchange) + " more " + intype + " than yesterday.";
            }
        } else {
            return intype.charAt(0).toUpperCase() + intype.slice(1) + " has remained constant at " + curval;
        }
    
    }

    function generatetotalcopy(loctype, datatype, header, rankdata, prankdata) {

        let rank,prank,maxval,pmaxval;

        switch (loctype) {
            case "region":
         
                maxval = rankdata.cnt;

                switch (datatype) {
                    case "total cases":
                        rank = rankdata.c;
                        // prank = prankdata.c;
                        break;
                    case "recoveries":
                        rank = rankdata.r;
                        // prank = prankdata.c;
                        break;
                    case "deaths":
                        rank = rankdata.d;
                        // prank = prankdata.c;
                        break;
                    default:
                        rank = null;
                }

                return(`${header} ranks ${ordinal_suffix_of(rank)} among the 17 regions in the Philippines in terms of ${datatype}.`);
            case "province":

                maxval = rankdata.cnt;
                pmaxval = prankdata.cnt;

                switch (datatype) {
                    case "total cases":
                        rank = rankdata.c;
                        prank = prankdata.c;
                        break;
                    case "recoveries":
                        rank = rankdata.r;
                        prank = prankdata.c;
                        break;
                    case "deaths":
                        rank = rankdata.d;
                        prank = prankdata.c;
                        break;
                    default:
                        rank = null;
                }

                return(`${header} ranks ${ordinal_suffix_of(rank)} of ${maxval} provinces in the region and ${ordinal_suffix_of(prank)} of ${pmaxval} provinces in the Philippines in terms of ${datatype}.`);
            case "municipality":
                maxval = rankdata.cnt;
                pmaxval = prankdata.cnt;

                switch (datatype) {
                    case "total cases":
                        rank = rankdata.c;
                        prank = prankdata.c;
                        break;
                    case "recoveries":
                        rank = rankdata.r;
                        prank = prankdata.c;
                        break;
                    case "deaths":
                        rank = rankdata.d;
                        prank = prankdata.c;
                        break;
                    default:
                        rank = null;
                }

                return(`${header} ranks ${ordinal_suffix_of(rank)} of ${maxval} municipalities in the province and ${ordinal_suffix_of(prank)} of ${pmaxval} municipalities in the Philippines interms of ${datatype}.`);

            default:
                return null;

        }

        // if (loctype === 'region') {
        //     return(`${header} ranks ${ordinal_suffix_of(rank)} among the 17 regions in the Philippines in terms of ${datatype}.`);
        //     // region ranks nth interms of total number of cases 
        // }

        // if (loctype === 'province') {
        //     return(`${header} ranks ${ordinal_suffix_of(rank)} of ${maxval} provinces in the region and ${ordinal_suffix_of(prank)} of ${pmaxval} provinces in the Philippines in terms of ${datatype}.`);
        // }
    }
    
    return(
        <Grid container spacing={1}>
            <Grid container item xs={12} spacing={0}>
                <Tricard
                    title1="Total cases"
                    value1={numberWithCommas(data.totaldata.c)}
                    copy1={generatetotalcopy(datatype, 'total cases', header, data.rank, data.prank)}

                    title2="New cases"
                    value2={numberWithCommas(data.dailystats.cases)}
                    copy2={generatechangecopy("cases",data.dailystats.cases,data.dailystats.caseschange)}

                    title3="Growth Rate"
                    value3={data.growthrate + "%"}
                    subtext3="(7-day avg)"
                    copy3="Percentage change from yesterday's total confirmed cases to today."

                    bgcolor='#29B6F6'
                    />

            </Grid>
            <Grid container item xs={12} spacing={0}>
                <Tricard
                    title1="Recoveries"
                    value1={numberWithCommas(data.totaldata.r)}
                    copy1={generatetotalcopy(datatype, 'recoveries', header, data.rank, data.prank)}

                    title2="New Recoveries"
                    value2={numberWithCommas(data.dailystats.recoveries)}
                    copy2={generatechangecopy("recoveries",data.dailystats.recoveries,data.dailystats.recoverieschange)}
                    

                    title3="Recovery Rate"
                    value3={data.recoveryrate}
                    copy3="Percentage of total recoveries divided by all confirmed cases. The higher, the better"

                    bgcolor='#4DB6AC'
                    />
            </Grid>

            <Grid container item xs={12} spacing={0}>
                <Tricard
                    title1="Deaths"
                    value1={numberWithCommas(data.totaldata.d)}
                    color1="#e57373"
                    copy1={generatetotalcopy(datatype, 'deaths', header, data.rank, data.prank)}

                    title2="New Deaths"
                    value2={data.dailystats.death}
                    copy2={generatechangecopy("deaths",data.dailystats.death,data.dailystats.deathchange)}

                    title3="Mortality Rate"
                    value3={data.mortalityrate}
                    copy3="Percentage of total deaths divided by all confirmed cases. The lower, the better. Global average is 6.6%"

                    bgcolor='#d32f2f'
                    />
            </Grid>

            <Grid container item xs={12} spacing={0}>
                <Grid item xs={12} sm={6}>
                    <StatCard
                        title="Active Cases"
                        value={numberWithCommas(data.totaldata.c - (data.totaldata.r + data.totaldata.d))}
                        copy="Cases that are still in hospitals, isolation facilities, or home quarantine"
                        // subtext={subtext3}
                        bgcolor='#29B6F6'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatCard
                        title="Doubling time"
                        value={data.doublingtime.toFixed(0) + " days"}
                        copy="An estimation of how many days current cases will double, based on a running 7-day average growth. Ideal doubling time is 30 days or longer."
                        // subtext={subtext3}
                        bgcolor="#F9A825"
                    />
                </Grid>
            </Grid>

            <Showmedpanel loctype={datatype} locid={header} />

        </Grid>
    )

}

export {
    Statspanel
};