import React from 'react';
import {Twocollboard, Threecollboard, Leaderboardloading } from '../components/Twocollboard';

import { ConvertDate } from '../components/Covidcommon';

import Fetchhistdata from '../hoc/Fetchhistdata';

import appConfig from '../config';

const Listcard = ({boarddataendpoint, type, col1, col2, col3, ...others}) => {
    // const [boardData, setBoardData] = useState(null);
    // const [endPoint, setEndPoint] = useState(boarddataendpoint);

    const { loading, results, error } = Fetchhistdata(appConfig.apiUrl + boarddataendpoint);

    let asofdate, tempdata;
    // let tabledata = null;

    // function ConvertDate(indate, intype) {
    
	// 	const monthsname = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	// 	const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
	// 	const [, year, month, day] = datePattern.exec(indate);
	// 	const tempLatestDate = new Date();
	// 	tempLatestDate.setFullYear(year,month-1,day);

	// 	switch (intype) {
	// 		case "monthshort":
	// 			return monthsname[tempLatestDate.getMonth()] + " " + ('00' + tempLatestDate.getDate()).slice(-2);
	// 		default:
	// 			return monthsname[tempLatestDate.getMonth()] + " " + tempLatestDate.getDate() + ", " + tempLatestDate.getFullYear();
	// 	}	
	// }

    if (loading) {
        return (
            <Leaderboardloading />
        )
    } else {
        
        asofdate = ConvertDate(results.asofdate);
        tempdata = JSON.stringify(results.data);
        tempdata = tempdata.replace(RegExp('"' + col1 + '":', 'g'),"\"col1\":");
        tempdata = tempdata.replace(RegExp('"' + col2 + '":', 'g'),"\"col2\":");

        if (typeof col3 !== 'undefined') {
            tempdata = tempdata.replace(RegExp('"' + col3 + '":', 'g'),"\"col3\":");
        }

        tempdata = JSON.parse(tempdata);
        
        if (type === '3col') {
            return (
                <Threecollboard asofdate={asofdate} boarddata={tempdata} {...others} />
            )
        } else {
            return(
                <Twocollboard asofdate={asofdate} boarddata={tempdata} {...others} />
            )
        }
    }

}

export default Listcard;