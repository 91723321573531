import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import Fetchhistdata from '../hoc/Fetchhistdata';

import appConfig from '../config';

import { usePaperStyles } from './Covidcommon';

import { Link,useHistory } from "react-router-dom";

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	tablecell: {
		width: '20%'
	}
});

export default function Municipalitylist(props) {

	const [page, setPage] = useState(0);
	const history = useHistory();
	const rowsPerPage = 5;
	let emptyRows;

	const classes = useStyles();
	const paperclasses = usePaperStyles();
	const {province,headername} = props;

	const endpointid = province.replace(/[^A-Za-z]/g,'').toLowerCase();

	const { loading, results, error } = Fetchhistdata(appConfig.apiUrl + 'list/municipality/' + endpointid);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };

	function handleClick(inlink) {
		history.push('/municipality/' + inlink.replace(/[^A-Za-z]/g,'').toLowerCase());
	}

	if (loading) {
		return(
			<p>Loading...</p>
		)
	} else {
		emptyRows = rowsPerPage - Math.min(rowsPerPage, results.data.length - page * rowsPerPage);
		return (
			// <TableContainer component={Paper}>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Paper square className={paperclasses.paper} elevation={2}>
					{headername !== province
					? <Typography style={{fontSize:'15px'}}>
						{headername} is part of <Link to={'/province/' + province.replace(/[^A-Za-z]/g,'').toLowerCase()}>{province}</Link>
					</Typography>
					: null
					}
					<Typography variant="caption" component="p">
						List of municipalities under {province}
					</Typography>
					<Table className={classes.table} aria-label="simple table" >
						<TableHead>
						<TableRow>
							<TableCell className={classes.tablecell}>Municipalities</TableCell>
							<TableCell className={classes.tablecell} align="right">Cases</TableCell>
							<TableCell className={classes.tablecell} align="right">Recy</TableCell>
							<TableCell className={classes.tablecell} align="right">Deaths</TableCell>
						</TableRow>
						</TableHead>
						<TableBody>
						{(rowsPerPage > 0
            			? results.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            			: results.data
          					).map((row) => (
							<TableRow hover style={{ cursor: 'pointer' }} key={row.hd} onClick={() => handleClick(row.hd)}>
								<TableCell component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
									{row.hd}
								</TableCell>
								<TableCell align="right">{row.c}</TableCell>
								<TableCell align="right">{row.r}</TableCell>
								<TableCell align="right">{row.d}</TableCell>
							</TableRow>
						))}

						{/* {emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={2} />
							</TableRow>
						)} */}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[5]}
									colSpan={4}
									count={results.data.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onChangePage={handleChangePage}
									/>
							</TableRow>
						</TableFooter>
					</Table>
				</Paper>
			</Grid>
			// </TableContainer>
		);

	}
}