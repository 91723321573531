import React, { useState, useEffect } from 'react';
import {Twocollboard, Threecollboard, Leaderboardloading} from '../components/Twocollboard';

import appConfig from '../config';

const Leaderboard = ({boarddataendpoint, type, col1, col2, col3, ...others}) => {
    // const [isLoaded, setIsLoaded] = useState(false);
    const [boardData, setBoardData] = useState(null);
    // const [endPoint, setEndPoint] = useState(boarddataendpoint);

    useEffect(() => {
        async function fetchData() {
            const res = await fetch(appConfig.apiUrl + boarddataendpoint);
            const json = await res.json();

            let tempdata = JSON.stringify(json);
            tempdata = tempdata.replace(RegExp('"' + col1 + '":', 'g'),"\"col1\":");
            tempdata = tempdata.replace(RegExp('"' + col2 + '":', 'g'),"\"col2\":");

            if (typeof col3 !== 'undefined') {
                tempdata = tempdata.replace(RegExp('"' + col3 + '":', 'g'),"\"col3\":");
            }
            // console.log(tempdata);
            // setBoardData(JSON.parse(tempdata.replace(RegExp('"' + myheader + '":', 'g'), "\"p\":")));

            // if (boarddataendpoint === "leaderboard/regionhighestavggrowthrate" ) {
            //     const tempdata = JSON.stringify(json);
            //     // tempdata.replace("\"r\":", "\"p\":");
            //     // const myheader = /\"region\"\:/g;
            //     const myheader = 'region';
            //     const myregex = RegExp('"' + myheader + '":', 'g');
            //     console.log(tempdata.replace(myregex, "\"p\":"));

            //     setBoardData(JSON.parse(tempdata.replace(myregex, "\"p\":")));
            // } else {

                setBoardData(JSON.parse(tempdata));
                // setIsLoaded(true);    
            // }

        }

        fetchData();
    },[boarddataendpoint]);

    if (boardData === null) {
        return (
            <Leaderboardloading />
        )
    } else {
        // console.log("Go! - " + boarddataendpoint);
        if (type === '3col') {
            return (
                <Threecollboard boarddata={boardData} {...others} />
            )
        } else {
            return(
                <Twocollboard boarddata={boardData} {...others} />
            )
        }
    }
    

}

export default Leaderboard;