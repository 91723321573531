import React, { PureComponent } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// import { BarChart, Bar, ResponsiveContainer, AreaChart, Area, LineChart, Line, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, Legend } from 'recharts';

import { LineChart, BarChart, Bar, ResponsiveContainer, Area, Line, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, Legend } from 'recharts';

import { usePaperStyles } from './Covidcommon';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CircularProgress from '@material-ui/core/CircularProgress';

const pieCustomLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, name, fill
    }) => {

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // const radius2 = innerRadius + (outerRadius - innerRadius) * 0.5;
    // const x2 = cx + radius2 * Math.cos(-midAngle * RADIAN);
    // const y2 = cy + radius2 * Math.sin(-midAngle * RADIAN);

    return (
        <React.Fragment>
        <text fontSize={25} x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {/* {`${(percent * 100).toFixed(0)}%`} */}
            {value !==0 ? `${value}` : null}
        </text>
        <text fontSize={11} x={x} y={y} dy={18} fill='#333' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {value !==0 ? `${name}` : null}
        </text>
        </React.Fragment>
    );
};

class CustomizedAxisTick extends PureComponent {
    render() {
      const {
        x, y, stroke, payload,
      } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-30)">{payload.value}</text>
        </g>
      );
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if ((active) && payload !== null) {
        // console.log(payload[0].payload.y);
      return (
        <div style={{backgroundColor: '#fff', padding: '2px', border: '1px solid #999'}}>
          <p style={{padding: '1px', margin: '1px'}}>{`${payload[0].payload.y}`}</p>
          {/* <p style={{padding: '1px', margin: '1px'}}>{`${payload[0].value}` + " " + tooltiptitle}</p> */}
          {/* <p style={{padding: '1px', margin: '1px'}}>{payload[0].value + " " + tooltiptitle}</p> */}
          <p style={{padding: '1px', margin: '1px'}}>{payload[0].value + " " + payload[0].name}</p>
          {payload.length >= 2 ?
          <p style={{padding: '1px', margin: '1px'}}>{payload[1].value + " " + payload[1].name}</p>
          :
          null
          }
          {payload.length >= 3 ?
          <p style={{padding: '1px', margin: '1px'}}>{payload[2].value + " " + payload[2].name}</p>
          :
          null
          }
          {payload.length >= 4 ?
          <p style={{padding: '1px', margin: '1px'}}>{payload[3].value + " " + payload[3].name}</p>
          :
          null
          }
          
          {/* <p style={{backgroundColor: '#eee', padding: '2px', border: '1px solid black'}}>{`${payload[0].value}` + " cases"}</p> */}
        </div>
      );
    }
  
    return null;
  };

const Casespiechart = ({chartdata})  => {
    // console.log(chartdata);
    const classes = usePaperStyles();

    const graphheight = 300;
    let piegraphheight;

    const theme = useTheme();
    const smmatches = useMediaQuery(theme.breakpoints.up('sm'));

    if (smmatches) {
      piegraphheight = graphheight;
    } else {
      piegraphheight = graphheight - 50;
    }

    const COLORS = ['#29B6F6', '#4DB6AC', '#d32f2f'];

    return(
        <Paper square className={classes.paper} elevation={2}>
            <Typography variant="h6" component="h6">
                Cases Breakdown
            </Typography>

            {/* <RenderGraph graphheight={300} customtick={false} chartdata={chartdata} graphtype={graphtype} />
             */}

            <ResponsiveContainer width='100%' height={piegraphheight}>
                <PieChart>
                <Pie dataKey="value" data={chartdata} fill="#8884d8" label={pieCustomLabel} labelLine={false}>
                    {
                    chartdata.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
                {/* <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
                {/* <Tooltip /> */}
                </PieChart>
            </ResponsiveContainer>

            <br />
            <Typography variant="body2" component="p">
                This chart shows the ratio between <span style={{color: '#64B5F6'}}>Active Cases</span>, <span style={{color:'#81C784'}}>Recoveries</span> and <span style={{color: '#e57373'}}>red (deaths)</span>. A mostly green pie chart is what we are hoping for.
            </Typography>
        </Paper>
    )
}

const Casesareachart = ({data, latestdate})  => {
    const classes = usePaperStyles();
    return(
        <Paper square className={classes.paper} elevation={2}>
            <Typography variant="h6" component="h6">
                Total cases vs <span style={{color: '#64B5F6'}}>Active cases</span> vs <span style={{color:'#81C784'}}>Recoveries</span> vs <span style={{color: '#e57373'}}>Death</span>
            </Typography>
            <Typography color="textSecondary" className={classes.boxContext} style={{marginTop:'5px', marginBottom:'5px'}}>
                Last 60 days Graph
            </Typography>
            <ResponsiveContainer width='100%' height={350}>
                <ComposedChart data={data}>
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis dataKey="y" height={50} tick={<CustomizedAxisTick />} />
                    <YAxis />
                    {/* <Legend /> */}
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <Line name="Total Cases" type="monotone" dataKey="Total" stroke="#000" strokeWidth={2} dot={false} />
                    <Area name="Active Cases" type="monotone" dataKey="Active" stroke="#888"  fill="#64B5F6" fillOpacity="0.8" />
                    <Area name="Death" type="monotone" dataKey="Death" stroke="#888" stackId="1" fill="#e57373" fillOpacity="0.8"  />
                    <Area name="Recoveries" type="monotone" dataKey="Recoveries" stroke="#888" stackId="1" fill="#81C784" fillOpacity="0.8" />
                </ComposedChart>
            </ResponsiveContainer>
            <br />
            <Typography variant="body2" component="p">
                This chart shows the most important curves in this pandemic. Ultimately we want a chart thats predominatly <span style={{color:'#81C784'}}>green (Recoveries)</span>, a flat or a downward trending <span style={{color: '#64B5F6'}}>blue (Active Cases)</span> area/line and as little <span style={{color: '#e57373'}}>red (deaths)</span> as possible.
            </Typography>
        </Paper>
    )
}

const Dailycaseschart = ({chartdata})  => {
    const classes = usePaperStyles();
    return(
        <Paper square className={classes.paper} elevation={2}>
            <Typography variant="h6" component="h6">
                Daily (<span style={{color: '#039BE5'}}>New cases</span> vs <span style={{color:'#4DB6AC'}}>Recoveries</span> vs <span style={{color: '#d32f2f'}}>Death</span>)
            </Typography>
            <Typography color="textSecondary" className={classes.boxContext} style={{marginTop:'5px', marginBottom:'5px'}}>
                Last 14 days Graph
            </Typography>
            {/* <RenderGraph graphheight={350} customtick={false} chartdata={chartdata} {...graphdetails} /> */}
            <ResponsiveContainer width='100%' height={350}>
                <BarChart data={chartdata} barGap={0} barCategoryGap={0}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="y" height={50} tick={<CustomizedAxisTick />} />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar name="New Cases" dataKey="x" fill="#039BE5" />
                    <Bar name="Recoveries" stackId="a" dataKey="x2" fill="#4DB6AC" />
                    <Bar name="Death" stackId="a" dataKey="x3" fill="#d32f2f" />
                </BarChart>
            </ResponsiveContainer>
            <br />
            <Typography variant="body2" component="p">
                This chart compares the number of <span style={{color: '#64B5F6'}}>new cases</span> vs <span style={{color:'#81C784'}}>recoveries</span> and <span style={{color: '#e57373'}}>deaths</span>. A higher number of new cases indicates an increasing number of active cases preventing us from "flattening the curve".
            </Typography>
        </Paper>
    )
}

const Dailysimplecaseschart = ({chartdata,datakey,title,titlecolor})  => {
    const classes = usePaperStyles();
    return(
        <Paper square className={classes.paper} elevation={2} style={{overflow:'hidden'}}>
            <Typography variant="h6" component="h6">
                <span style={{color: titlecolor}}>{title}</span>
            </Typography>
            <Typography color="textSecondary" className={classes.boxContext} style={{marginTop:'5px', marginBottom:'5px'}}>
                Last 90 days Graph
            </Typography>
            {/* <RenderGraph graphheight={350} customtick={false} chartdata={chartdata} {...graphdetails} /> */}
            <ResponsiveContainer width='100%' height={350}>
                <BarChart data={chartdata} barGap={0} barCategoryGap={0} isAnimationActive={false}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="y" height={50} tick={<CustomizedAxisTick />} />
                    <YAxis />
                    {/* <Tooltip content={<CustomTooltip />} /> */}
                    <Tooltip />
                    <Bar name={title} dataKey={datakey} fill={titlecolor} isAnimationActive={false} />
                    {/* <Bar name="Recoveries" stackId="a" dataKey="x2" fill="#4DB6AC" /> */}
                    {/* <Bar name="Death" stackId="a" dataKey="x3" fill="#d32f2f" /> */}
                </BarChart>
            </ResponsiveContainer>
            <br />
            {/* <Typography variant="body2" component="p">
                This chart compares the number of <span style={{color: '#64B5F6'}}>new cases</span> vs <span style={{color:'#81C784'}}>recoveries</span> and <span style={{color: '#e57373'}}>deaths</span>. A higher number of new cases indicates an increasing number of active cases preventing us from "flattening the curve".
            </Typography> */}
        </Paper>
    )
}

const Dailysimplecaseschartwithsma = ({chartdata,datakey,smakey,title,titlecolor,description})  => {
    const classes = usePaperStyles();
    return(
        <Paper square className={classes.paper} elevation={2} style={{overflow:'hidden'}}>
            <Typography variant="h6" component="h6">
                <span style={{color: titlecolor}}>{title}</span>
            </Typography>
            <Typography color="textSecondary" className={classes.boxContext} style={{marginTop:'5px', marginBottom:'5px'}}>
                Last 90 days Graph
            </Typography>
            {/* <RenderGraph graphheight={350} customtick={false} chartdata={chartdata} {...graphdetails} /> */}
            <ResponsiveContainer width='100%' height={350}>
                <ComposedChart data={chartdata} barGap={0} barCategoryGap={0} isAnimationActive={false}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="y" height={50} tick={<CustomizedAxisTick />} />
                    <YAxis />
                    {/* <Tooltip content={<CustomTooltip />} /> */}
                    <Tooltip />
                    <Bar name={title} dataKey={datakey} fill={titlecolor} isAnimationActive={false} />
                    <Line name='7-days Moving Average' type="monotone" dataKey={smakey} dot={false} strokeWidth={2} stroke="#000" isAnimationActive={false} />
                </ComposedChart>
            </ResponsiveContainer>
            <br />
            <Typography variant="body2" component="p">
                {description}
            </Typography>
        </Paper>
    )
}

const Dailystackcaseschartwithsma = ({chartdata,stackdatakey1,stackdatakey2,smakey,title,titlecolor,data1color,data2color, data1name, data2name,description})  => {
    const classes = usePaperStyles();
    return(
        <Paper square className={classes.paper} elevation={2} style={{overflow:'hidden'}}>
            <Typography variant="h6" component="h6">
                <span style={{color: titlecolor}}>{title}</span>
            </Typography>
            <Typography color="textSecondary" className={classes.boxContext} style={{marginTop:'5px', marginBottom:'5px'}}>
                Last 90 days Graph
            </Typography>
            {/* <RenderGraph graphheight={350} customtick={false} chartdata={chartdata} {...graphdetails} /> */}
            <ResponsiveContainer width='100%' height={350}>
                <ComposedChart data={chartdata} barGap={0} barCategoryGap={0} isAnimationActive={false}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="y" height={50} tick={<CustomizedAxisTick />} />
                    <YAxis />
                    {/* <Tooltip content={<CustomTooltip />} /> */}
                    <Tooltip />
                    <Legend />
                    <Bar name={data1name} dataKey={stackdatakey1} fill={data1color} stackId="a" isAnimationActive={false} />
                    <Bar name={data2name} dataKey={stackdatakey2} fill={data2color} stackId="a" isAnimationActive={false} />
                    <Line name='7-days Moving Average' type="monotone" dataKey={smakey} dot={false} strokeWidth={2} stroke="#000" isAnimationActive={false} />
                </ComposedChart>
            </ResponsiveContainer>
            <br />
            <Typography variant="body2" component="p">
                {description}
            </Typography>
        </Paper>
    )
}

const Comparisonlinechart = ({chartdata,line1datakey,line2datakey,line1color,line2color,line1name,line2name,title,titlecolor,description})  => {
    const classes = usePaperStyles();
    return(
        <Paper square className={classes.paper} elevation={2} style={{overflow:'hidden'}}>
            <Typography variant="h6" component="h6">
                <span style={{color: titlecolor}}>{title}</span>
            </Typography>
            <Typography color="textSecondary" className={classes.boxContext} style={{marginTop:'5px', marginBottom:'5px'}}>
                Last 90 days Graph
            </Typography>
            {/* <RenderGraph graphheight={350} customtick={false} chartdata={chartdata} {...graphdetails} /> */}
            <ResponsiveContainer width='100%' height={350}>
                <LineChart data={chartdata} isAnimationActive={false}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="y" height={50} tick={<CustomizedAxisTick />} />
                    <YAxis width={40} />
                    {/* <Tooltip content={<CustomTooltip />} /> */}
                    <Tooltip />
                    <Line name={line1name} type="monotone" dataKey={line1datakey} dot={false} strokeWidth={2} stroke={line1color} isAnimationActive={false} />
                    <Line name={line2name} type="monotone" dataKey={line2datakey} dot={false} strokeWidth={2} stroke={line2color} isAnimationActive={false} />
                </LineChart>
            </ResponsiveContainer>
            <br />
            <Typography variant="body2" component="p">
                {description}
            </Typography>
        </Paper>
    )
}

const Valtimechart = ({chartdata,datakey,title,titlecolor,loading,description})  => {
    const classes = usePaperStyles();
    return(
        <Paper square className={classes.paper} elevation={2} style={{overflow:'hidden'}}>
            <Typography variant="h6" component="h6" style={{paddingBottom: 30}}>
                <span style={{color: titlecolor}}>{title}</span>
            </Typography>
            {/* <Typography color="textSecondary" className={classes.boxContext} style={{marginTop:'5px', marginBottom:'5px'}}>
                Last 90 days Graph
            </Typography> */}
            {loading
            ? <div style={{height: 350, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
                <CircularProgress size={100} disableShrink />
              </div>
            : <ResponsiveContainer width='100%' height={350}>
                <BarChart data={chartdata.data} barGap={0} barCategoryGap={0}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis height={50} dataKey="t" tick={<CustomizedAxisTick />}/>
                    <YAxis />
                    {/* <Tooltip content={<CustomTooltip />} /> */}
                    <Tooltip />
                    <Bar name={title} dataKey={datakey} fill={titlecolor} isAnimationActive={false} />
                </BarChart>
            </ResponsiveContainer>
            }
            <br />
            <Typography variant="body2" component="p">
                {description}
            </Typography>
        </Paper>
    )
}


export { Casespiechart, Casesareachart, Dailycaseschart,Dailysimplecaseschart, Dailysimplecaseschartwithsma, Valtimechart, Dailystackcaseschartwithsma, Comparisonlinechart };