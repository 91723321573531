import React, { useState } from 'react';

import { usePaperStyles } from '../components/Covidcommon';
import Paper from '@material-ui/core/Paper';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// import { Link } from "react-router-dom";

import {MapChart, Showmaphoverdata} from './Mapchart';

import Fetchhistdata from '../hoc/Fetchhistdata';

import appConfig from '../config';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// import FormHelperText from '@material-ui/core/FormHelperText';

import ReactTooltip from "react-tooltip";

// const geoProvUrl = "/mapdata/philmap-prov.json";
const geoProvUrl = "/mapdata/phil-prov-v1.json";
// const geoProvUrl = "/mapdata/phil-prov-v2.json";
const geoRegionUrl = "/mapdata/phil-region-v1.json";

const Philmap = () => {

    const [toolTipContent, setToolTipContent] = useState([]);
    const [mapType, setMapType] = React.useState('Province');

    const [dataType, setDataType] = React.useState({
		typeselected: "active",
	});

    const classes = usePaperStyles();
    // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const theme = useTheme();
    const issm = useMediaQuery(theme.breakpoints.up('sm'));
    const ismd = useMediaQuery(theme.breakpoints.up('md'));

    let mapheight = 900;
    let papersize = '510px';
    let mapscale = 2900;
    let maptypemargin = '10px 0px 0px 0px';

    if (issm) {
        papersize = '800px';
        mapheight = 790;
        mapscale = 2600;
        maptypemargin = '0px 0px 0px 50px';
    }

    if (ismd) {
        papersize = '810px';
        mapheight = 1030;
        mapscale = 3500;
        maptypemargin = '0px 0px 0px 50px';
    }

    //Load data
    const ncdataprov = Fetchhistdata(appConfig.apiUrl + "geographic/cases/newprov");
    const ncdataregion = Fetchhistdata(appConfig.apiUrl + "geographic/cases/newregion");
    const provsumdata = Fetchhistdata(appConfig.apiUrl + "summary/prov");
    const regionsumdata = Fetchhistdata(appConfig.apiUrl + "summary/region");

    //Load Healthcare data
    const hcdataregion = Fetchhistdata(appConfig.apiUrl + "geographic/healthcare/regionsum");
    const hcdataprov = Fetchhistdata(appConfig.apiUrl + "geographic/healthcare/provsum");

    //Change data select
    const handleChange = (event) => {
		const name = event.target.name;
		setDataType({
		  ...dataType,
		  [name]: event.target.value,
		});
    };
    
    //Radio Button Handler
    const handleRadioChange = (event) => {
		setMapType(event.target.value);
	};

    // const handleClick = (e, inregionid) => {
    //     // console.log(regionid);
    //     setSelectedRegion(inregionid);
    // }

    if (ncdataprov.loading || ncdataregion.loading || provsumdata.loading || regionsumdata.loading || hcdataregion.loading || hcdataprov.loading) {
        return(
            <Paper square className={classes.paper} elevation={2} style={{height:papersize}}>
                <p>Loading...</p>
            </Paper>
        )
    } else {
        return(
            <Paper square className={classes.paper} elevation={2} style={{height:papersize}}>
                <div>
                    <FormControl>
                        <InputLabel htmlFor="age-native-helper">Select Data:</InputLabel>
                        <NativeSelect
                        value={dataType.typeselected}
                        onChange={handleChange}
                        name="typeselected"
                        // className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'typeselected' }}
                        >
                        {/* <option aria-label="None" value="" /> */}
                        <option value="total">Total Cases</option>
                        <option value="active">Active Cases</option>
                        <option value="recoveries">Recoveries</option>
                        <option value="death">Deaths</option>
                        <option value="growthrate">Avg Growth Rate</option>
                        <option value="recoveryrate">Recovery Rate</option>
                        <option value="mortalityrate">Mortality Rate</option>
                        <option value="newcases">New Cases</option>
                        <option value="healthcare">Healthcare Facilities</option>
                        </NativeSelect>
                    </FormControl>

                    <FormControl component="fieldset" style={{margin: maptypemargin}}>
                        <FormLabel component="legend" style={{fontSize: 11}}>Map Type</FormLabel>
                        {/* <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}> */}
                        <RadioGroup row aria-label="gender" name="gender1" value={mapType} onChange={handleRadioChange}>
                            <FormControlLabel value="Province" control={<Radio />} label="Province" />
                            <FormControlLabel value="Region" control={<Radio />} label="Region" />
                        </RadioGroup>
                    </FormControl>
                </div>
        
                <MapChart
                    setTooltipContent={setToolTipContent}
                    maptype={mapType}
                    mapdata={mapType === 'Province' ? provsumdata.results.data : regionsumdata.results.data}
                    ncdata={mapType === 'Province' ? ncdataprov.results.data : ncdataregion.results.data}
                    hcdata={mapType === 'Province' ? hcdataprov.results.data : hcdataregion.results.data}
                    datatype={dataType.typeselected}
                    geodata={mapType === 'Province' ? geoProvUrl : geoRegionUrl}
                    mapheight={mapheight}
                    mapscale={mapscale}
                    />

                <ReactTooltip>
                    {toolTipContent.length > 0 ?
                    <div>
                        {mapType === 'Province' 
                        ? 'Province: ' + toolTipContent[0] 
                        : 'Region: ' + toolTipContent[0]
                        }
                        <br />
                        <Showmaphoverdata locdata={toolTipContent[2]} />
                    </div>
                    : null
                    }
                </ReactTooltip>

            </Paper>
        )
    }
}

export { Philmap };