import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import Fetchhistdata from '../hoc/Fetchhistdata';

import appConfig from '../config';

import { usePaperStyles } from './Covidcommon';

import { Link, useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	tablecell: {
		width: '20%'
	}
});

export default function Provincelist(props) {
	// console.log(props.regionid);
	const classes = useStyles();
	const paperclasses = usePaperStyles();
	const {regionid, headername} = props;

	const history = useHistory();
	
	const {loading, results, error} = Fetchhistdata(appConfig.apiUrl + 'list/province/' + regionid.replace(/[^A-Za-z]/g,'').toLowerCase());
	
	function handleClick(inlink) {
		history.push('/province/' + inlink.replace(/[^A-Za-z]/g,'').toLowerCase());
	  }

	if (loading) {
		return(
			<p>Loading...</p>
		)
	} else {
		return (
			// <TableContainer component={Paper}>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Paper square className={paperclasses.paper} elevation={2}>
					{typeof headername !== 'undefined'
					? <Typography style={{fontSize:'15px'}}>
						{headername} is part of <Link to={'/region/' + regionid.replace(/[^A-Za-z]/g,'').toLowerCase()}>{regionid}</Link>
					</Typography>
					: null
					}
					<Typography variant="caption" component="p">
						List of provinces under {regionid}
					</Typography>
					<Table className={classes.table} aria-label="simple table" >
						<TableHead>
						<TableRow>
							<TableCell className={classes.tablecell}>Provinces</TableCell>
							<TableCell className={classes.tablecell} align="right">Cases</TableCell>
							<TableCell className={classes.tablecell} align="right">Recoveries</TableCell>
							<TableCell className={classes.tablecell} align="right">Deaths</TableCell>
						</TableRow>
						</TableHead>
						<TableBody>
						{/* {reloading ? null : results.data.map((row) => ( */}
						{results.data.map((row) => (
							<TableRow hover style={{ cursor: 'pointer' }} key={row.hd} onClick={()=>handleClick(row.hd)}>
								<TableCell component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
									{row.hd}
								</TableCell>
								<TableCell align="right">{row.c}</TableCell>
								<TableCell align="right">{row.r}</TableCell>
								<TableCell align="right">{row.d}</TableCell>
							</TableRow>
						))}
						</TableBody>
					</Table>
				</Paper>
			</Grid>
			// </TableContainer>
		);

	}
}