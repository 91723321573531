// import React from 'react';
import React, { Suspense, lazy } from 'react';

import Header from './components/Header';
// import National from './containers/National';
import Footer from './components/Footer';
// import Activecases from './containers/Activecases';
// import Trends from './containers/Trends';
// import Province from './containers/Province';

// import maxBy from 'lodash/maxBy';

// import apiConfig from './config';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	// useLocation
  } from "react-router-dom";


// import Nation2 from './containers/Nationalv2';
// import Regionhistdisplay from './containers/Regionhistdisplay';
import Genhistdisplayv2 from './containers/Genhistdisplayv2';
// import Dohdatastats from './containers/Dohdatastats';
// import Graphmania from './containers/Graphmania';
// import Genhistdisplay from './containers/Genhistdisplay';

const Graphmania = lazy(() => import('./containers/Graphmania'));
const Privacy = lazy(() => import('./components/Privacy'));
const Genhistdisplay = lazy(() => import('./containers/Genhistdisplay'));


const Dohdatastats = lazy(() => import('./containers/Dohdatastats'));

// import Choropleth from './containers/Choropleth';
const Choropleth = lazy(() => import('./containers/Choropleth'));

const Newcasesoverview = lazy(() => import('./containers/Newcasesoverview'));

const Leaderboards = lazy(() => import('./containers/Leaderboards'));

const Tabular = lazy(() => import('./containers/Tabular'));

const Majorcitiesgraphs = lazy(() => import('./containers/Majorcitiesgraph'));

// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

function App() {

	return(
		<Router>
		{/* <Router basename="/covid"> */}
			<Header />
			{/* <ScrollToTop /> */}
			<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				<Route path='/tabular' component={Tabular} />
				<Route path='/leaderboards' component={Leaderboards} />
				<Route path='/newcasesoverview' component={Newcasesoverview} />
				<Route path='/choropleth' component={Choropleth} />
				<Route path='/dohdatastats' component={Dohdatastats} />
				<Route path='/curves/metromanila' component={Graphmania} />
				<Route path='/keycities' component={Majorcitiesgraphs} />
				<Route path="/privacy" component={Privacy} />
				<Route path='/municipality/:provinceId' component={Genhistdisplay} />
				{/* <Route path='/province/:provinceId' component={Genhistdisplay} /> */}
				<Route path='/province/:endpointID' component={Genhistdisplayv2} />
				{/* <Route path='/region/:regionId' component={Regionhistdisplay} /> */}
				{/* <Route path='/' component={Nation2} /> */}
				<Route path='/region/:endpointID' component={Genhistdisplayv2} />
				<Route path='/' component={Genhistdisplayv2} />
			</Switch>
			</Suspense>
			<Footer />
		</Router>
	)
}

export default App;
