import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Listcard from '../containers/Listcard';
// import Leaderboard from '../containers/Leaderboard';

function Newcaseslocation() {
    return(
        <Container maxWidth="lg">
            <Grid container spacing={1}>
                <Listcard 
                    boarddataendpoint="geographic/cases/new"
                    title="New Cases Location"
                    header1="Municipality"
                    header2="New Cases"
                    col1="mu"
                    col2="nc"
                    copy="Data from the DOH identifying where the new cases are from, and how many."
                />

                <Listcard 
                    boarddataendpoint="geographic/cases/recovered"
                    title="New Recy Location"
                    header1="Municipality"
                    header2="Recoveries"
                    col1="mu"
                    col2="recy"
                    copy="Data from the DOH identifying where the new recoveries are from, and how many."
                /> 

                <Listcard 
                    boarddataendpoint="geographic/cases/died"
                    title="New Death Location"
                    header1="Municipality"
                    header2="Death"
                    col1="mu"
                    col2="died"
                    copy="Data from the DOH identifying where the new fatalties are from, and how many."
                />
            </Grid>

            <Grid container spacing={2}>
                <Listcard 
                    boarddataendpoint="geographic/cases/newbrgy"
                    title="Brgy. New Cases"
                    header1="Municipality"
                    header2="Barangay"
                    header3="New Cases"
                    type="3col"
                    col1="mu"
                    col2="bg"
                    col3="nc"
                    copy="Data from the DOH identifying where the new cases are from, and how many."
                />
            </Grid>
        </Container>
        
    )

}

export default Newcaseslocation;