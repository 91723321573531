import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Divider from '@material-ui/core/Divider';

import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

let theme = createMuiTheme();

theme.typography.caption = {
    fontSize: '15px',
    '@media (min-width:600px)': {
      fontSize: '12px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '11px',
    },
  };

const useStylesCard = makeStyles({
    root: {
    //   minWidth: 200,
        // width: '100%'
        color: 'white',
        height: '100%'
        // minHeight: '200px'
    },
    // bullet: {
    //   display: 'inline-block',
    //   margin: '0 2px',
    //   transform: 'scale(0.8)',
    // },
    title: {
      fontSize: 15,
      fontWeight: 500
    },
    pos: {
      marginBottom: 12,
    },
});


function StatCard(props) {
    const classes = useStylesCard();
    const {title, value, subtext, copy, bgcolor} = props;
  
    return (
        
      <Card square className={classes.root} style={{backgroundColor: bgcolor}}>
        <CardContent>
            <ThemeProvider theme={theme}>
            <Typography className={classes.title} color="textSecondary">
                {title}
            </Typography>
            <Typography variant="h5" component="h2">
                {value}
            </Typography>
            <Typography variant="caption" className={classes.pos} color="textSecondary">
                {/* {typeof subtext === 'undefined' ? '\u00A0' : subtext} */}
                {subtext}
            </Typography>
            <Typography variant="caption" component="p">
                {copy}
            </Typography>
            </ThemeProvider>
        </CardContent>
      </Card>
      
    );
}

function Title(props) {
    return (
      <Typography component="h2" variant="subtitle2" style={{color: props.color}}>
		  	<Box fontWeight="fontWeightRegular">
        		{props.children}
			</Box>
      </Typography>
    );
}

const usePaperStyles = makeStyles((theme) => ({
	root: {
	// flexGrow: 1,
	},
	paper: {
	padding: theme.spacing(2),
	// paddingLeft: theme.spacing(2),
	// paddingRight: theme.spacing(2),
	display: 'flex',
	// overflow: 'auto',
	flexDirection: 'column',
	},
	outerpaper: {
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2)
	},
	fixedHeight: {
	height: 450,
	},
}));

function ArrowIconDirection(props) {

	switch (props.direction) {
		case 'up':
			return <ArrowUpwardIcon style={{fontSize: 15}} />
			// break;
		case 'down':
			return <ArrowDownwardIcon style={{fontSize: 15}} />
			// break;
		default:
			return "-";
	}

}

const Headinfo = ({title, value, change, reversetrendcolor, color}) => {

    const percentchange = Math.abs(((change / value) * 100).toFixed(0));

    let textcolor = 'textSecondary';
	let badcolor, goodcolor;
	let arrowdirection = null;

    if (reversetrendcolor) {
      badcolor = 'green';
	  goodcolor = 'red';
    } else {
      badcolor = 'red';
	  goodcolor ='green';
    }

    //good bad trend
    if (change !== 0) {
      if (change < 0 ) {
		textcolor = badcolor;
		arrowdirection = 'down';
      } else {
		textcolor = goodcolor;
		arrowdirection = 'up';
      }
    }

    return(
        <React.Fragment>
            <Title color={color}>{title}</Title>
			<div style={{display: 'flex'}}>
                <Typography component="h2" variant="h5" style={{marginBottom:'5px'}}>
                    {value}
                </Typography>
                {typeof change !== 'undefined' ?
                <Typography component="p" variant="caption" style={{marginTop: '7px', marginLeft: '5px', color: textcolor, display: 'flex'}}>
                    {change} ({percentchange}%) <ArrowIconDirection direction={arrowdirection} />
                </Typography>
                :
                null
                }
            </div>
        </React.Fragment>
    );
}

const Doubletopinfo = ({ title1, value1, change1, revcolor1, title2, value2, change2, revcolor2 }) => {

	return (
	<div style={{display: 'flex'}}>
		<div style={{width:'50%'}}>
            <Headinfo
                title={title1}
                value={value1}
                change={change1}
                reversetrendcolor={revcolor1}
            />
        </div>
        <Divider orientation="vertical" flexItem />
        <div style={{paddingLeft: '10px', width:'50%'}}>
            <Headinfo
                title={title2}
                value={value2}
                change={change2}
                reversetrendcolor={revcolor2}
            />
		</div>
	</div>
	)
}

const Tripletopinfo = ({ title1, value1, change1, revcolor1, color1, title2, value2, change2, revcolor2, color2, title3, value3, change3, revcolor3, color3 }) => {

	return (
	<div style={{display: 'flex'}}>
		<div style={{width:'33%'}}>
            <Headinfo
                title={title1}
                value={value1}
                change={change1}
                reversetrendcolor={revcolor1}
                color={color1}
            />
        </div>
        <Divider orientation="vertical" flexItem />
        <div style={{paddingLeft: '10px', width:'33%'}}>
            <Headinfo
                title={title2}
                value={value2}
                change={change2}
                reversetrendcolor={revcolor2}
                color={color2}
            />
		</div>
        <Divider orientation="vertical" flexItem />
        <div style={{paddingLeft: '10px', width:'33%'}}>
            <Headinfo
                    title={title3}
                    value={value3}
                    change={change3}
                    reversetrendcolor={revcolor3}
                    color={color3}
                />
		</div> 
	</div>
	)
}

const useLoaderStyles = makeStyles((theme) => ({
	root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems:'center',
		// '& > * + *': {
        
		// marginLeft: theme.spacing(2),
	//   },
	},
  }));

function Coviddatacardloading(props) {

    const {height} = props;
	// const classes = usePaperStyles();
	const loadclasses = useLoaderStyles();

	// const {status} = props;

	// const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
	// console.log("Status: " + status);

  	return (
		// <Grid item {...griddata}>
			<Paper elevation={2} style={{height:height}}>
				<div className={loadclasses.root}>
					<CircularProgress size={100} disableShrink />
					{/* <CircularProgress color="secondary" /> */}
				</div>
			</Paper>
		// </Grid>
	);
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function ConvertDate(indate, intype) {
    
    const monthsname = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
    const [, year, month, day] = datePattern.exec(indate);
    const tempLatestDate = new Date();
    tempLatestDate.setFullYear(year,month-1,day);

    switch (intype) {
        case "monthshort":
            return monthsname[tempLatestDate.getMonth()] + " " + ('00' + tempLatestDate.getDate()).slice(-2);
        default:
            return monthsname[tempLatestDate.getMonth()] + " " + tempLatestDate.getDate() + ", " + tempLatestDate.getFullYear();
    }	
}

export { Title, Headinfo, Doubletopinfo, Tripletopinfo, Coviddatacardloading, usePaperStyles, StatCard, numberWithCommas, ConvertDate }