import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

export default () => {
    return(
        <Container maxWidth="lg">
            <br />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div style={{textAlign: 'left'}}>
                        <Link href="https://www.facebook.com/TribalWorldwidePH/" target="_blank">
                        <img alt="Tribal Worldwide"  style={{paddingRight: '30px'}} src={window.location.origin + '/images/tribal-80x80.png'} />
                        </Link>

                        <Link href="http://www.agileintelligence.ph/" target="_blank">
                        <img alt="Agile Intelligence"  style={{paddingRight: '10px'}} src={window.location.origin + '/images/Agile-80x80.png'} />
                        </Link>

                        <Typography variant="body2" component="p" gutterBottom>
                        16F Two World Square, McKinley Hill, Fort Bonifacio, Taguig City, Philippines<br />
                        © 2020 All rights reserved.
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div style={{textAlign: 'Right'}}>
                        <Typography variant='caption' component="p">
                            data source: <a href="https://ncovtracker.doh.gov.ph/" target="_blank">ncovtracker.doh.gov.ph</a>
                            {/* <iframe style={{width: 280, height: 40}} src="/datasource/datadrop.txt"></iframe>  */}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}