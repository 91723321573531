function Processhistdata(inrawdata) {
 
    function Compute7dayaverage(inarray, inindex) {
    
        let tempsum = 0;
        let validdata = 0;

		for (let index = 0; index < 7; index++) {

            if (typeof inarray[inindex-index] === 'undefined' || typeof inarray[inindex-index-1] === 'undefined') {
                tempsum += 0;
            } else {
                tempsum += inarray[inindex-index].x;
                validdata++;
            }
            
		}

        // return Math.round(tempsum/7);
        if (validdata === 0)  {
            // console.log(inarray[inindex]);
            return (inarray[inindex]).x;
        } else {
            return Math.round(tempsum/validdata);
        }

    }


	function ConvertDate(indate, intype) {
    
		const monthsname = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

		const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
		const [, year, month, day] = datePattern.exec(indate);
		const tempLatestDate = new Date();
		tempLatestDate.setFullYear(year,month-1,day);

		switch (intype) {
			case "monthshort":
				return monthsname[tempLatestDate.getMonth()] + " " + ('00' + tempLatestDate.getDate()).slice(-2);
			default:
				return monthsname[tempLatestDate.getMonth()] + " " + tempLatestDate.getDate() + ", " + tempLatestDate.getFullYear();
		}	
	}

	function Computepctchg(inchg,inorigval) {
		if ((inchg === 0) || (inorigval === 0)) {
			return 0;
		} else {
			return parseFloat(((inchg/inorigval) * 100).toFixed(2));
		}
    }
    
    // useEffect(() => {
    //     function processData() {

            let mainheader;
            let json;
            
            if (typeof inrawdata.name === 'undefined') {
                mainheader = 'Philippines';
                json = inrawdata;
            } else {
                mainheader = inrawdata.name;
                json = inrawdata.data;
            }

            
            const todayindex = json.length - 1;

            const resolvedcases = json[todayindex].r + json[todayindex].d;
            const newcases = json[todayindex].c - json[todayindex-1].c;
            const newrecoveries = json[todayindex].r - json[todayindex-1].r;
            const newdeath = json[todayindex].d - json[todayindex-1].d;
            const activecases = json[todayindex].c - resolvedcases;
            const caseschange = newcases - (json[todayindex-1].c - json[todayindex-2].c);
            const recoverieschange = newrecoveries - (json[todayindex-1].r - json[todayindex-2].r);
            const deathchange = newdeath - (json[todayindex-1].d - json[todayindex-2].d);
            const activecasestrend = (json[todayindex].c - resolvedcases) - (json[todayindex-1].c - (json[todayindex-1].r + json[todayindex-1].d));

            let tempcases30daysdata = [];
            let tempactivecases30daysdata = [];
            let temprecoveries30daysdata = [];
            let tempdeath30daysdata = [];
            let tempdodata = [];
            let temptabledata = [];

            let tempnewcaseschgrate = [];
            let tempnewcasespctchgrate = [];

            let tempactivecaseschgrate = [];
            let tempactivecasespctchgrate = [];
            
            let temprecoverieschgrate = [];
            let temprecoveriespctchgrate = [];

            let tempdeathchgrate = [];
            let tempdeathpctchgrate = [];

            let  maxstatslenght = 90;

            let tempdailycomb30daysdata = [];

            let totalsdata = [];

            if (json.length < 90) {
                maxstatslenght = json.length;
            }

            for (let index = json.length-maxstatslenght; index < json.length; index++) {
                tempcases30daysdata.push({ y: ConvertDate(json[index].t, 'monthshort'), x: (json[index].c - json[index-1].c)});
                tempactivecases30daysdata.push({ y: ConvertDate(json[index].t, 'monthshort'), x: (json[index].c - (json[index].r + json[index].d)), x2: json[index].c, x3:(json[index].r + json[index].d)});
                temprecoveries30daysdata.push({ y: ConvertDate(json[index].t, 'monthshort'), x: json[index].r - json[index-1].r});
                tempdeath30daysdata.push({ y: ConvertDate(json[index].t, 'monthshort'), x: json[index].d - json[index-1].d});
                tempdodata.push({x:json[index].do - json[index-1].do});

                totalsdata.push({
                    y: ConvertDate(json[index].t, 'monthshort'),
                    Active: (json[index].c - (json[index].r + json[index].d)), x2: json[index].c, x3:(json[index].r + json[index].d),
                    Recoveries: json[index].r,
                    Death: json[index].d,
                    Total: json[index].c
                })
                
                // tempdailycomb30daysdata.push({
                //     y: ConvertDate(json[index].t, 'monthshort'),
                //     x: json[index].c - json[index-1].c,
                //     x2: json[index].r - json[index-1].r,
                //     x3: json[index].d - json[index-1].d,
                //     do: json[index].do - json[index-1].do,
                // })

                // console.log(Compute7dayaverage(tempactivecases30daysdata, tempcases30daysdata.length-1));
                // console.log(tempactivecases30daysdata);

                // x2: json[index].r - json[index-1].r,

                tempdailycomb30daysdata.push({
                    y: ConvertDate(json[index].t, 'monthshort'),
                    c: tempcases30daysdata[tempcases30daysdata.length-1].x,
                    r: temprecoveries30daysdata[temprecoveries30daysdata.length-1].x,
                    d: tempdeath30daysdata[tempdeath30daysdata.length-1].x,
                    do: tempdodata[tempdodata.length-1].x,
                    csma: Compute7dayaverage(tempcases30daysdata, tempcases30daysdata.length-1),
                    rsma: Compute7dayaverage(temprecoveries30daysdata, temprecoveries30daysdata.length-1),
                    dsma: Compute7dayaverage(tempdeath30daysdata, tempdeath30daysdata.length-1),
                    dosma: Compute7dayaverage(tempdodata, tempdodata.length-1),
                })


                temptabledata.push({
                    t: ConvertDate(json[index].t, 'monthshort'),
                    a: tempactivecases30daysdata[tempactivecases30daysdata.length-1].x,
                    n: tempcases30daysdata[tempcases30daysdata.length-1].x,
                    r: temprecoveries30daysdata[temprecoveries30daysdata.length-1].x,
                    d: tempdeath30daysdata[tempdeath30daysdata.length-1].x
                });

                

                // tempconfirmcaseschg.push({x:(json[index].c) - (json[index-1].c)});

                tempnewcaseschgrate.push({x:(json[index].c - json[index-1].c) - (json[index-1].c - json[index-2].c)});
                tempnewcasespctchgrate.push({x: Computepctchg(tempnewcaseschgrate[tempnewcaseschgrate.length-1].x,tempcases30daysdata[tempcases30daysdata.length-1].x)});

                tempactivecaseschgrate.push({x:(json[index].c - (json[index].r + json[index].d)) - (json[index-1].c - (json[index-1].r + json[index-1].d)) });
                tempactivecasespctchgrate.push({x: Computepctchg(tempactivecaseschgrate[tempactivecaseschgrate.length-1].x,tempactivecases30daysdata[tempactivecases30daysdata.length-1].x)});
                temprecoverieschgrate.push({x:(json[index].r - json[index-1].r) - (json[index-1].r - json[index-2].r)});
                temprecoveriespctchgrate.push({x: Computepctchg(temprecoverieschgrate[temprecoverieschgrate.length-1].x,temprecoveries30daysdata[temprecoveries30daysdata.length-1].x)});

                tempdeathchgrate.push({x:(json[index].d - json[index-1].d) - (json[index-1].d - json[index-2].d)});
                tempdeathpctchgrate.push({x: Computepctchg(tempdeathchgrate[tempdeathchgrate.length-1].x,tempdeath30daysdata[tempdeath30daysdata.length-1].x)});
            }

            //growth rate 

            let temptotalgrowthrate = 0
            for (let c = json.length-7; c < json.length; c++) {
                temptotalgrowthrate += ((json[c].c - json[c - 1].c)/json[c - 1].c) * 100;
            }

            // console.log((temptotalgrowthrate/7));
            // console.log(Math.log(2) / Math.log(1 + ((temptotalgrowthrate/7)/100)));

            let smacasestemp = [];
            let	smaactivecases = [];
            let smarecoveries = [];
            let smadeath = [];

            let smanewcaseschgrate = [];
            let smanewcasespctchg = [];

            let smaactivecaseschgrate = [];
            let smaactivecasespctchgrate = [];
            
            let smarecoverieschgrate = [];
            let smarecoveriespctchgrate = [];

            let smadeathchgrate = [];
            let smadeathpctchgrate = [];

            for (let index = tempcases30daysdata.length-14; index < tempcases30daysdata.length; index++) {
                smacasestemp.push({ y: tempcases30daysdata[index].y, x: Compute7dayaverage(tempcases30daysdata, index) });
                smaactivecases.push({ y: tempactivecases30daysdata[index].y, x: Compute7dayaverage(tempactivecases30daysdata, index) });
                smarecoveries.push({ y: temprecoveries30daysdata[index].y, x: Compute7dayaverage(temprecoveries30daysdata, index) });
                smadeath.push({ y: tempdeath30daysdata[index].y, x: Compute7dayaverage(tempdeath30daysdata, index) });
                
                smaactivecaseschgrate.push({ y: tempactivecases30daysdata[index].y, x: Compute7dayaverage(tempactivecaseschgrate, index)});
                smaactivecasespctchgrate.push({ y: tempactivecases30daysdata[index].y, x: Compute7dayaverage(tempactivecasespctchgrate, index)});
                smanewcaseschgrate.push({ y: tempcases30daysdata[index].y, x: Compute7dayaverage(tempnewcaseschgrate, index)});
                smanewcasespctchg.push({ y: tempactivecases30daysdata[index].y, x: Compute7dayaverage(tempnewcasespctchgrate, index)});
                smarecoverieschgrate.push({ y: tempcases30daysdata[index].y, x: Compute7dayaverage(temprecoverieschgrate, index)});
                smarecoveriespctchgrate.push({ y: temprecoveries30daysdata[index].y, x: Compute7dayaverage(temprecoveriespctchgrate, index)});
                smadeathchgrate.push({ y: tempcases30daysdata[index].y, x: Compute7dayaverage(tempdeathchgrate, index)});
                smadeathpctchgrate.push({ y: tempdeath30daysdata[index].y, x: Compute7dayaverage(tempdeathpctchgrate, index)});
            }

            let piedata = [
                {name: 'Active', value: activecases},
                {name: 'Recovered', value: json[todayindex].r},
                {name: 'Died', value: json[todayindex].d}
            ]

            // const graphhistoricaldaylength = 30;

            let temprnk = inrawdata.rnk;
            //check if rank data is available
            if (typeof temprnk === 'undefined') {
                temprnk = {c: 0, r: 0, d: 0, cnt: 0}
            }

            let tempprnk = inrawdata.prnk;
            if (typeof tempprnk === 'undefined') {
                tempprnk = {c: 0, r: 0, d: 0, cnt: 0}
            }

            //final data assignment
            const tempprovincedata = {
                mainheader: mainheader,
                dailystats: {
                    cases: newcases,
                    caseschange: caseschange,
                    recoveries: newrecoveries,
                    recoverieschange: recoverieschange,
                    death: newdeath,
                    deathchange: deathchange
                },
                growthrate: ((temptotalgrowthrate/7)).toFixed(2),
                doublingtime: (Math.log(2) / Math.log(1 + ((temptotalgrowthrate/7)/100))),
                // combdailydata: tempdailycomb30daysdata.slice(tempdailycomb30daysdata.length - graphhistoricaldaylength),
                combdailydata: tempdailycomb30daysdata,
                casedivision: piedata,
                totalsdata: totalsdata,
                data30days: tempactivecases30daysdata,
                latestdate: ConvertDate(json[todayindex].t),
                activecases: activecases,
                activecasestrend: activecasestrend,
                mortalityrate: (((json[todayindex].d / json[todayindex].c) * 100).toFixed(2) + "%"),
                recoveryrate: (((json[todayindex].r / json[todayindex].c) * 100).toFixed(2) + "%"),

                totalcases: (json[todayindex].c + " total confirm cases (ytd)"),
                totalresolvedcases: (resolvedcases + " total resolved cases (ytd)"),
                totaltecoveries: (json[todayindex].r + " total recoveries (ytd)"),
                totaldeath: (json[todayindex].d + " total death (ytd)"),
                newcases14daysdata: tempcases30daysdata.slice(tempcases30daysdata.length - 14),
                activecases14daysdata: tempactivecases30daysdata.slice(tempactivecases30daysdata.length - 14),
                recoveries14daysdata: temprecoveries30daysdata.slice(temprecoveries30daysdata.length - 14),
                death14daysdata: tempdeath30daysdata.slice(tempdeath30daysdata.length - 14),
                tabledata: temptabledata.slice(temptabledata.length - 14),
                totaldata: {c: json[todayindex].c, r: json[todayindex].r, d: json[todayindex].d},
                regionid: inrawdata.regionid,
                province: (typeof inrawdata.province === 'undefined' ? mainheader : inrawdata.province),
                rank: temprnk,
                prank: tempprnk,
            }

    return tempprovincedata;
}

export default Processhistdata;