import React from 'react';

import { Valtimechart } from '../components/Charts';

import Fetchhistdata from '../hoc/Fetchhistdata';

import appConfig from '../config';

function Showrecoverytimechart({location,sectiontype}) {

    let isloading = true;

    // let finaldata, peak, tempsumtime = 0, tempsumcases = 0;
    let finaldata = [], tempdesciption = '';
    
    if (typeof location !== 'undefined' && location !== null) {

        // console.log(location);

        // const recoverytimedata = Fetchhistdata(appConfig.apiUrl + "casesstats/" + location + "/recoverytime");
        const recoverytimedata = Fetchhistdata(`${appConfig.apiUrl}casesstats/${sectiontype}/${location.replace(/[^A-Za-z]/g,'').toLowerCase()}/recoverytime`);

        
        if (!recoverytimedata.loading) {

            if (recoverytimedata.results.length > 0) {
                let tempsumtime = 0, tempsumcases = 0;
                
                //Get Peak
                let peak = recoverytimedata.results[recoverytimedata.results.length - 1].t;
                // peak = recoverytimedata.results.length;

                //Day Generator
                let tempdata = [],tempmatch;
                for (let index = 1; index < recoverytimedata.results[recoverytimedata.results.length - 1].t; index++) {
                    // const element = array[index];
                    tempmatch = recoverytimedata.results.find(data => data.t === index)
                    // console.log(tempmatch);
                    if (tempmatch) {
                        tempsumtime += (tempmatch.t * tempmatch.c)
                        tempsumcases += tempmatch.c;

                        tempdata.push({
                            t: index + " Days", c: tempmatch.c
                        })
                    } else {
                        tempdata.push({
                            t: index + " Days", c: 0
                        })
                    }
                    
                }

                finaldata = {
                    data: tempdata
                }

                tempdesciption = `The average recovery time is ${Math.round(tempsumtime/tempsumcases)} days. The longest recorded recovery time is ${peak} days.`;
                isloading = false;
            } else {
                finaldata = {
                    data: []
                }
                tempdesciption = "No Data Avalable";
                isloading = false;
            }
        }
    }

    return(
        <Valtimechart
            chartdata={finaldata}
            datakey = 'c'
            title = 'Days of illness to recoveries'
            titlecolor = '#4DB6AC'
            loading={isloading}
            description={tempdesciption}
        />
    )
}

function Showdeadillnesschart({location,sectiontype}) {

    let isloading = true;

    // let finaldata, peak, tempsumtime = 0, tempsumcases = 0;
    let finaldata = [], tempdesciption = '';

    if (typeof location !== 'undefined' && location !== null) {

        // const deathtimedata = Fetchhistdata(appConfig.apiUrl + "casesstats" + location + "/deathtime");
        const deathtimedata = Fetchhistdata(`${appConfig.apiUrl}casesstats/${sectiontype}/${location.replace(/[^A-Za-z]/g,'').toLowerCase()}/deathtime`);

        if (!deathtimedata.loading) {

            if (deathtimedata.results.length > 0) {
                let tempsumtime = 0, tempsumcases = 0;

                //Get Peak
                let peak = deathtimedata.results[deathtimedata.results.length - 1].t;

                //Add Days
                // let tempdata = deathtimedata.results.map(data => {
                //     tempsumtime += (data.t * data.c)
                //     tempsumcases += data.c;
                //     return {t: data.t + " Days", c: data.c}
                // })

                // console.log(tempdata);

                //Day Generator
                let tempdata = [],tempmatch;
                for (let index = 1; index < deathtimedata.results[deathtimedata.results.length - 1].t; index++) {
                    // const element = array[index];
                    tempmatch = deathtimedata.results.find(data => data.t === index)
                    // console.log(tempmatch);
                    if (tempmatch) {
                        tempsumtime += (tempmatch.t * tempmatch.c)
                        tempsumcases += tempmatch.c;

                        tempdata.push({
                            t: index + " Days", c: tempmatch.c
                        })
                    } else {
                        tempdata.push({
                            t: index + " Days", c: 0
                        })
                    }
                    
                }
                // console.log(tempdata);

                finaldata = {
                    data: tempdata
                }
                tempdesciption = `The average days of illness for a deadly case is ${Math.round(tempsumtime/tempsumcases)} days. The longest illness recorded is ${peak} days.`;

                isloading = false;
            } else {
                finaldata = {
                    data: []
                }
                tempdesciption = "No Data Avalable";
                isloading = false;
            }
        }
    }

    return(
        <Valtimechart
            chartdata={finaldata}
            datakey = 'c'
            title = 'Days of illness to death'
            titlecolor = '#d32f2f'
            loading={isloading}
            description={tempdesciption}
        />
    )
}

function Showdelaychart({endpoint, title, color}) {

    let isloading = true;

    // let finaldata, peak, tempsumtime = 0, tempsumcases = 0;
    let finaldata = [], tempdesciption = '';
    
    // const recoverytimedata = Fetchhistdata(appConfig.apiUrl + "casesstats/" + location + "/recoverytime");
    // const confdelaydata = Fetchhistdata(`${appConfig.apiUrl}dohdatastats/confirmationgraph`);
    const confdelaydata = Fetchhistdata(`${appConfig.apiUrl}${endpoint}`);
        
    if (!confdelaydata.loading) {

        if (confdelaydata.results.length > 0) {
            // let tempsumtime = 0, tempsumcases = 0;
            
            //Get Peak
            // let peak = confdelaydata.results[confdelaydata.results.length - 1].t;
            // peak = recoverytimedata.results.length;

            //Day Generator
            let tempdata = [],tempmatch;
            for (let index = 1; index < Number(confdelaydata.results[confdelaydata.results.length - 2].t) + 1; index++) {
                // const element = array[index];
                tempmatch = confdelaydata.results.find(data => Number(data.t) === index)
                // console.log(tempmatch);
                if (tempmatch) {
                    // tempsumtime += (tempmatch.t * tempmatch.c)
                    // tempsumcases += tempmatch.c;

                    tempdata.push({
                        t: index + " Days", c: tempmatch.c
                    })
                } else {
                    tempdata.push({
                        t: index + " Days", c: 0
                    })
                }
                
            }

            tempdata.push({
                t: confdelaydata.results[confdelaydata.results.length - 1].t + " Days", c: confdelaydata.results[confdelaydata.results.length - 1].c
            })

            finaldata = {
                data: tempdata
            }
            // console.log(finaldata);

            // tempdesciption = `The average recovery time is ${Math.round(tempsumtime/tempsumcases)} days. The longest recorded recovery time is ${peak} days.`;
            isloading = false;
        } else {
            finaldata = {
                data: []
            }
            // tempdesciption = "No Data Avalable";
            isloading = false;
        }
    }

    return(
        <Valtimechart
            chartdata={finaldata}
            datakey = 'c'
            // title = 'No. of Cases per Day Delay in Reporting'
            title = {title}
            // titlecolor = '#29B6F6'
            titlecolor = {color}
            loading={isloading}
            // description={tempdesciption}
        />
    )
}

export {  Showrecoverytimechart, Showdeadillnesschart, Showdelaychart };